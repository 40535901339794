import UserService from "@api/userService";
import {usePaymentSettings} from "@hooks/selectors/panel.selectors";
import {PaymentAgregator} from "@interfaces/consumer/payments";
import {Form, Formik} from "formik";
import {toastr} from "react-redux-toastr";
import * as Yup from 'yup';
import {FC, PropsWithChildren, useCallback} from "react";
import {FormHelper} from "@constructor/components/core/forms";
import {EPaymentCurrency} from "@interfaces/user/payments";
import {useMetrics} from "@hooks/panelHooks";
import {useLocalizedBlock} from "@services/hooks";
import {ObjectSchema} from "yup";

interface DonateFormValues {
    count: number;
    aggregator: PaymentAgregator;
    tag?: string;
    currency: EPaymentCurrency;
}

interface DonateFormProps {
    initialValues?: Partial<DonateFormValues>;
}

const DonateForm: FC<PropsWithChildren<DonateFormProps>> = ({ children, initialValues, ...props }) => {

    const { donateMin, donateMax, currencies, defaultCurrency } = usePaymentSettings();
    const aggregators: PaymentAgregator[] = [PaymentAgregator.PayPalych, PaymentAgregator.Enot, PaymentAgregator.Prime, PaymentAgregator.Antilopay];
    const { reachGoal } = useMetrics();

    const globalYup = useLocalizedBlock('yup.message.errors');
    const formMessages = useLocalizedBlock('forms.donate');

    const validate: ObjectSchema<DonateFormValues> = Yup.object({
        count: Yup.number()
            .when('currency', ([currency], field) => {
                const currencyData = currencies.find(x => x.currency === currency);
                const min = currencyData?.donateMin || donateMin;
                const max = currencyData?.donateMax || donateMax;

                return field
                    .min(min, formMessages['yup.errors.notless']
                        .replace('{min}', String(min))
                        .replace('{currency}', currency))
                    .max(max, formMessages['yup.errors.notmore']
                        .replace('{max}', String(max))
                        .replace('{currency}', currency));
            })
            .integer(globalYup['integer'])
            .required(globalYup['required']),
        aggregator: Yup.mixed<PaymentAgregator>()
            .oneOf(aggregators, globalYup['required'])
            .required(globalYup['required']),
        tag: Yup.string(),
        currency: Yup.mixed<EPaymentCurrency>().required()
    });

    const onSubmit = useCallback(async (values: DonateFormValues) => {

        const page = window.open('about:blank', '_blank');

        const api = new UserService();
        const result = await api.donateRequest({
            agregator: values.aggregator,
            sum: values.count,
            tag: values.tag
        });

        if (result.success && result.data.redirect) {
            page?.location.replace(result.data.redirect);
            reachGoal('donate');
        }
        else {
            if (result.errorCode === 'NotEnabled') {
                toastr.error(formMessages['toast.title'], formMessages['errors.not_enabled']);
            }
            else if (result.errorCode === 'PaymentError') {
                toastr.error(formMessages['toast.title'], formMessages['errors.payment_error']);
            }
            else if (result.errorCode === 'WrongSum') {
                if(result.errorData) {
                    toastr.error(formMessages['toast.title'], formMessages['errors.wrong_sum_range']
                        .replace('{min}', String(result.errorData.min))
                        .replace('{max}', String(result.errorData.max)));
                }
                else {
                    toastr.error(formMessages['toast.title'], formMessages['errors.wrong_sum']);
                }
            }
            else {
                toastr.error(formMessages['toast.title'], formMessages['errors.unknown']);
            }
            page?.close();
        }
    }, [formMessages, reachGoal]);

    const initial: DonateFormValues = {
        count: 100,
        aggregator: aggregators[0],
        tag: '',
        currency: defaultCurrency,
        ...initialValues
    };

    return (
        <Formik
            initialValues={initial}
            validationSchema={validate}
            onSubmit={onSubmit}>
            <Form {...props}>
                <FormHelper>
                    {children}
                </FormHelper>
            </Form>
        </Formik>
    );
}

export default DonateForm;