import {createContext, useContext} from "react";

export interface LocalizationContext {
    currentLanguageCode: string;
    currentLanguageName: string;
    setCurrentLanguage: (language: string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const LocalizationContext = createContext<LocalizationContext>(undefined as any);
export const LocalizationProvider = LocalizationContext.Provider;

export function useLocalizationContext(): LocalizationContext | undefined {
    return useContext(LocalizationContext);
}