import { GoalInput } from "@components/consumer/helpers";
import { FormikHelpers, useFormik } from "formik";
import { FC, useMemo } from "react";
import { Button, Col, Input, Form, FormFeedback, FormGroup, Label } from "reactstrap";
import * as Yup from 'yup';
import {ObjectSchema} from "yup";

export interface RefTargetFormValues {
    goalId: string;
    isActive: boolean;
}

interface RefTargetFormProps {
    initialValues: RefTargetFormValues;
    onSubmit: (values: RefTargetFormValues, actions: FormikHelpers<RefTargetFormValues>) => Promise<any>;
    editForm?: boolean;
}

const RefTargetForm: FC<RefTargetFormProps> = ({ initialValues, onSubmit, editForm }) => {

    const validationSchema: ObjectSchema<RefTargetFormValues> = useMemo(() => Yup.object({
        goalId: Yup.string()
            .required(),
        isActive: Yup.boolean()
            .required()
    }), []);

    const {
        values,
        isSubmitting,
        isValid,
        handleBlur,
        //handleChange,
        handleSubmit,
        setFieldValue,
        //touched,
        errors
    } = useFormik({
        onSubmit,
        initialValues,
        validationSchema
    });

    return (
        <Form onSubmit={handleSubmit} onBlur={handleBlur}>
            <FormGroup row>
                <Label sm={2}>Активно</Label>
                <Col sm={10}>
                    <FormGroup check>
                        <Input
                            type="switch"
                            id="isActive"
                            name="isActive"
                            checked={values.isActive}
                            onChange={e => setFieldValue('isActive', e.target.checked)} />
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>Достижение</Label>
                <Col sm={10} role='group'>
                    <GoalInput name='goalId' value={values.goalId} setFieldValue={setFieldValue} />
                    <FormFeedback valid={false}>{errors.goalId}</FormFeedback>
                </Col>
            </FormGroup>
            <div className="text-center mt-3">
                <Button type="submit" color="primary" disabled={isSubmitting || !isValid}>
                    {editForm ? 'Сохранить' : 'Создать'}
                </Button>
            </div>
        </Form>
    )
}

export default RefTargetForm;