import {ChangeEvent, FC, PropsWithChildren, RefObject, useCallback, useEffect, useMemo, useRef} from "react";
import {useTaskMessage, useTopicAuto} from "@hooks/selectors/user/support.selectors";
import * as Yup from 'yup';
import {Form, Formik, FormikHelpers, useFormikContext} from "formik";
import {usePromisedDispatch} from "@hooks/helperHooks";
import {userActions} from "@store/user";
import {toastr} from "react-redux-toastr";
import {injectChild} from "@components/constructor";
import {AttachmentType} from "@interfaces/user/support";
import {useSupportTopicContext} from "@constructor/components/features/support/context";
import {useLocalizedBlock} from "@services/hooks";

export interface SupportTopicInputFormValues {
    message: string;
    images: File[];
}

interface SupportTopicInputFormProps {
    cancel_edit_message?: () => void;
    max_attachments_count: number;
}

const SupportTopicInputForm: FC<PropsWithChildren<SupportTopicInputFormProps>> = ({ cancel_edit_message, children, max_attachments_count = 1, ...props }) => {

    const dispatch = usePromisedDispatch();

    const {
        attachedImages = [],
        editableMessageId,
        setAttachedImages,
        uploadAttachedImages
    } = useSupportTopicContext() ?? {};
    const topic = useTopicAuto();
    const editableMessage = useTaskMessage(topic?.id, editableMessageId);

    const formMessages = useLocalizedBlock('forms.support.topic.input');

    const initialValues: SupportTopicInputFormValues = {
        message: "",
        images: []
    };

    const validation = useMemo(() => Yup.object().shape({
        message: Yup.string()
            .max(1000, formMessages['validation.maxMessage']?.replace('{max}', '1000'))
            .when('images', (images, schema) => {
                return images.length === 0 ? schema.required(formMessages['validation.required']) : schema;
            })
    }), [formMessages]);

    const onSubmit = useCallback(async ({ message }: SupportTopicInputFormValues, actions: FormikHelpers<SupportTopicInputFormValues>) => {
        if(!topic?.id){
            return;
        }

        const id = topic.id;
        const result = editableMessage?.id ?
            await dispatch(userActions.editTaskMessage(topic.id, editableMessage.id, { message })) :
            await dispatch(userActions.addTaskMessage({
                task: id,
                message,
                attachments: attachedImages?.map(file => ({
                    name: file.file.name,
                    type: AttachmentType.Image
                }))
            }));

        if (result.success) {
            cancel_edit_message && cancel_edit_message();
            dispatch(userActions.loadTask(id));
            dispatch(userActions.loadTaskMessages(id));
            const attaches = result.data.attachments.filter(a => a.uploadFile);
            if(attaches.length > 0 && attachedImages.length > 0 && uploadAttachedImages) {
                await uploadAttachedImages(attaches.map(a => a.uploadFile!));
            }
            actions.resetForm();
            setAttachedImages && setAttachedImages([]);
        }
        else {
            if(result.errorCode === "EmptyMessage"){
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.empty_message']);
            }
            else if(result.errorCode === 'TopicNotFound') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.topic_not_found']);
                dispatch(userActions.loadTask(id));
            }
            else if(result.errorCode === 'ClosedTopic') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.closed_topic']);
                dispatch(userActions.loadTask(id));
            }
            else if(result.errorCode === 'MessageNotFound') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.message_not_found']);
                dispatch(userActions.loadTask(id));
            }
            else if(result.errorCode === 'NotOwner') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.not_owner']);
                dispatch(userActions.loadTask(id));
            }
            else if(result.errorCode === "TooFast"){
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.too_fast']);
            }
            else if(result.errorCode === 'TooLate') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.too_late']);
            }
            else {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.unknown']);
            }
        }
    }, [attachedImages, cancel_edit_message, dispatch, editableMessage?.id, formMessages, setAttachedImages, topic?.id, uploadAttachedImages]);

    if(!topic) {
        return null;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}>
            <Form {...props}>
                <EditMessageHelper 
                    topic_id={topic?.id} 
                    message_id={editableMessageId}
                    images_max_count={max_attachments_count}>
                    {children}
                </EditMessageHelper>
            </Form>
        </Formik>
    );
};

interface EditMessageHelperProps {
    topic_id?: string;
    message_id?: string;
    images_max_count: number;
}

interface EditMessageHelperChildValues {
    image_input_ref: RefObject<HTMLInputElement>;
    image_input_click: () => void;
    image_input_changed: (event: ChangeEvent<HTMLInputElement>) => any;
    images_attached: boolean;
}

const EditMessageHelper: FC<PropsWithChildren<EditMessageHelperProps>> = ({ topic_id, message_id, images_max_count, children }) => {

    const { attachedImages, setAttachedImages } = useSupportTopicContext() ?? {};
    const message = useTaskMessage(topic_id, message_id);
    const { setFieldValue, values: { images } } = useFormikContext<SupportTopicInputFormValues>();

    useEffect(() => {
        setFieldValue('message', message?.message ?? '');
    }, [message?.message, setFieldValue]);

    useEffect(() => {
        if(images.length > images_max_count) {
            const slice = images.slice(0, images_max_count);
            setFieldValue('images', slice);
            setAttachedImages && setAttachedImages(slice);
        }
    }, [images, images_max_count, setAttachedImages, setFieldValue]);

    const imageInputRef = useRef<HTMLInputElement>(null);
    const clickImageInput = useCallback(() => {
        imageInputRef.current?.click();
    }, []);
    
    const imageInputChanged = useCallback(({currentTarget: {files}}: ChangeEvent<HTMLInputElement>) => {
        const array = (files ? Array.from(files) : []).slice(0, images_max_count);
        setFieldValue('images', array);
        
        setAttachedImages && setAttachedImages(array);
    }, [images_max_count, setFieldValue, setAttachedImages]);

    const imagesAttached = !!attachedImages && attachedImages.length > 0;
    const data = useMemo<EditMessageHelperChildValues>(() => ({
        image_input_ref: imageInputRef,
        image_input_click: clickImageInput,
        images_attached : imagesAttached,
        image_input_changed: imageInputChanged
    }), [clickImageInput, imageInputChanged, imagesAttached]);

    return injectChild(data, children);
};

export default SupportTopicInputForm;