import { getComponent } from "@components/constructor";
import {useLocalizedTexts} from "@hooks/languageHooks";
import classNames from "classnames";
import { Field, useFormikContext } from "formik";
import { FC } from "react";
import { FormFieldProps } from ".";

const FormField: FC<FormFieldProps & any> = ({
                                                 children,
                                                 className,
                                                 name,
                                                 emptyClassName,
                                                 invalidClassName,
                                                 filledClassName,
                                                 ...props
                                             }) => {

    const newProps = { ...props };

    const { errors, touched, values } = useFormikContext<any>();
    const value = values[name];

    const textProps = Object.values(newProps).filter(x => typeof x === 'string');
    const localizedTexts = useLocalizedTexts(textProps);
    Object.entries(props).forEach(([key, value]) => {
        if (typeof value !== 'string') {
            return;
        }

        const localized = localizedTexts[value];
        if (localized) {
            newProps[key] = localized;
        }
    });

    if (newProps.component && typeof newProps.component === 'string') {
        newProps.component = getComponent(newProps.component);
    }

    const invalid = Boolean(errors[name] && touched[name]);

    const cn = classNames(className, invalidClassName && {
        [invalidClassName]: invalid,
        [emptyClassName]: !value,
        [filledClassName]: value
    });

    return (
        <Field
            className={cn}
            name={name}
            invalid={invalid.toString() || undefined}
            {...newProps}>
            {children}
        </Field>
    )
}

export default FormField;