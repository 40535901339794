import { PaginationProps } from "@constructor/components/core/pagination";
import { useCallback, useMemo, useState } from "react";

export function usePagination(sizePerPage: number, totalSize: number): PaginationProps {
    const [page, setPage] = useState(0);

    const maxPage = Math.ceil(totalSize / sizePerPage) - 1;

    const prevPage = useCallback(() => setPage((prev) => Math.max(0, prev - 1)), [setPage]);
    const nextPage = useCallback(() => setPage((prev) => Math.min(maxPage, prev + 1)), [maxPage, setPage]);

    return useMemo<PaginationProps>(() => ({
        pagination_page_name: page + 1,
        pagination_current_page: page,
        pagination_next_page: nextPage,
        pagination_per_page: sizePerPage,
        pagination_prev_page: prevPage,
        pagination_set_page: setPage,
        pagination_total_pages: maxPage + 1
    }), [maxPage, nextPage, page, prevPage, setPage, sizePerPage]);
}