import { useQueryById } from "@hooks/selectors/user/query.selectors";
import { FC } from "react";
import { useQueryResultContext } from "./context";

interface QueryTableBodyProps {
    bodyProps?: Record<string, any>;
    rowProps?: Record<string, any>;
    columns?: Record<string, any>;
}

const QueryTableBody: FC<QueryTableBodyProps> = ({bodyProps, rowProps, columns = {}}) => {

    const result = useQueryResultContext();
    const query = useQueryById(result?.queryId || '');

    if (!query?.columns || !result?.rows) {
        return null;
    }

    const key = query.columns.find(c => c.key)?.name;

    if (!key) {
        return null;
    }

    if(typeof columns !== 'object') {
        console.error('Column props in QueryTableBody must be an object');
        columns = {};
    }

    return (
        <tbody {...bodyProps}>
            {
                result.rows.map(row => (
                    <tr key={row[key]} {...rowProps}>
                        {
                            query.columns.map(column => {
                                if (column.hidden) {
                                    return null;
                                }

                                return (
                                    <td key={column.name} {...columns[column.name]}>{row[column.name]}</td>
                                )
                            })
                        }
                    </tr>
                ))
            }
        </tbody>
    )
}

export default QueryTableBody;