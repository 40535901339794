import MessageAttachment from "./messageAttachment";
import MessageAttachmentsList from "./messageAttachmentsList";
import {ConstructionComponentInfo} from "@interfaces/constructor/constructor";

const SupportMessageAttachmentInfo: ConstructionComponentInfo = {
    component: MessageAttachment,
    addedChildProps: [
        'attachment_loading',
        'attachment_url',
        'attachment_file_name'
    ],
    injectChild: [
        'attachment_file_id'
    ],
    requiredParent: [
        'SupportMessageAttachmentsList'
    ],
    requiredProps: [
        'attachment_file_id'
    ]
}

const SupportMessageAttachmentsListInfo: ConstructionComponentInfo = {
    component: MessageAttachmentsList,
    addedChildProps: [
        'attachment_id',
        'attachment_file_id'
    ]
}

export {
    SupportMessageAttachmentInfo,
    SupportMessageAttachmentsListInfo
}