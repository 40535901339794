import { FC, useCallback, useMemo } from "react";
import { IConsumerGift } from "@interfaces/consumer/daily";
import { EGiftType } from "@interfaces/user/daily";
import * as Yup from 'yup';
import { BalanceGiftInfo, ItemGiftInfo, PatchDailyGiftModel } from "@models/consumer/daily";
import ConsumerDailyService from "@api/consumer/consumerDailyService";
import { toastr } from "react-redux-toastr";
import { Field, Form, Formik } from "formik";
import { Button, Col, Input, FormGroup, FormText, Label } from "reactstrap";
import { FormikRowInput } from "@components/panel/formik";
import { FormikItemInput } from "../helpers";

interface EditDailyGiftFormProps {
    gift: IConsumerGift;
}

interface EditDailyGiftFormValues {
    name: string;
    days: number;
    isActive: boolean;
    repeat: boolean;
    oneTime: boolean;
    balance: number;
    itemId: string;
    count: number;
    type: EGiftType;
}

const EditDailyGiftForm: FC<EditDailyGiftFormProps> = ({ gift }) => {

    const initial: EditDailyGiftFormValues = {
        name: gift.name,
        days: gift.days,
        isActive: gift.isActive,
        repeat: gift.repeat,
        oneTime: gift.oneTime,
        balance: (gift.giftInfo as BalanceGiftInfo).balance || 0,
        itemId: (gift.giftInfo as ItemGiftInfo).itemId || '',
        count: (gift.giftInfo as ItemGiftInfo).count || 0,
        type: gift.giftType
    }

    const validate = useMemo(() => Yup.object({
        name: Yup.string()
            .min(3)
            .max(100)
            .required(),
        days: Yup.number()
            .min(1)
            .max(1000)
            .required(),
        isActive: Yup.boolean(),
        repeat: Yup.boolean(),
        oneTime: Yup.boolean(),
        balance: Yup.number()
            .when('type', {
                is: EGiftType.BalanceGift,
                then: schema => schema
                    .max(10000000)
                    .required()
            }),
        itemId: Yup.string()
            .when('type', {
                is: EGiftType.ItemGift,
                then: schema => schema
                    .required()
            }),
        count: Yup.number()
            .when('type', {
                is: EGiftType.ItemGift,
                then: schema => schema
                    .min(1)
                    .max(2000000000)
                    .required()
            })
    }), []);

    const onSubmit = useCallback(async (values: EditDailyGiftFormValues) => {

        const info = values.type === EGiftType.BalanceGift ? {
            balance: values.balance
        } as BalanceGiftInfo : {
            itemId: values.itemId,
            count: values.count
        } as ItemGiftInfo;

        const model: PatchDailyGiftModel = {
            id: gift.id,
            name: values.name,
            days: values.days,
            isActive: values.isActive,
            repeat: values.repeat,
            oneTime: values.oneTime,
            info
        };

        const api = new ConsumerDailyService();
        const result = values.type === EGiftType.BalanceGift ?
            await api.patchBalanceGift(model) :
            await api.patchItemGift(model);

        if (result.success) {
            toastr.success("Подарки", "Подарок успешно обновлен");
        }
        else {
            if (result.errorCode === 'DisabledItem') {
                toastr.error("Подарки", "Данный предмет недоступен");
            }
            else if (result.errorCode === 'InvalidGiftType') {
                toastr.error("Подарки", "Неизвестный тип подарка");
            }
            else {
                toastr.error("Подарки", "Что-то пошло не так");
            }
        }
    }, [gift.id]);

    return (
        <Formik
            initialValues={initial}
            validationSchema={validate}
            onSubmit={onSubmit}>
            {
                ({ values, setFieldValue, isSubmitting, isValid }) => (
                    <Form>
                        <Field
                            label="Название"
                            type="text"
                            name="name"
                            placeholder="Введите название"
                            component={FormikRowInput} />
                        <Field
                            label="День"
                            type="number"
                            name="days"
                            min={1}
                            max={1000}
                            placeholder="1-1000"
                            description="День, на который будет выдан бонус"
                            component={FormikRowInput} />
                        <FormGroup row>
                            <Label sm={3}>Единоразово</Label>
                            <Col sm={9}>
                                <FormGroup check>
                                    <Input
                                        type="switch"
                                        id="oneTime"
                                        name="oneTime"
                                        checked={values.oneTime}
                                        onChange={e => setFieldValue('oneTime', e.target.checked)} />
                                    <FormText>Подарок может быть выдан только один раз, даже при сбросе счетчика</FormText>
                                </FormGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3}>Повторяем</Label>
                            <Col sm={9}>
                                <FormGroup check>
                                    <Input
                                        type="switch"
                                        id="repeat"
                                        name="repeat"
                                        checked={values.repeat}
                                        onChange={e => setFieldValue('repeat', e.target.checked)} />
                                    <FormText>Если включено - бонус будет выдаваться ежедневно</FormText>
                                </FormGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3}>Активно</Label>
                            <Col sm={9}>
                                <Input
                                    type="switch"
                                    id="isActive"
                                    name="isActive"
                                    checked={values.isActive}
                                    onChange={e => setFieldValue('isActive', e.target.checked)} />
                            </Col>
                        </FormGroup>
                        {
                            values.type === EGiftType.BalanceGift && (
                                <Field
                                    label="Сумма"
                                    type="number"
                                    name="balance"
                                    min={1}
                                    max={10000000}
                                    placeholder="1-10000000"
                                    description="Сумма, которую будет давать подарок"
                                    component={FormikRowInput} />
                            )
                        }
                        {
                            values.type === EGiftType.ItemGift && (
                                <>
                                    <FormGroup row>
                                        <Label sm={3}>Предмет</Label>
                                        <Col sm={9} role='group'>
                                            <Field
                                                name='itemId'
                                                component={FormikItemInput} />
                                        </Col>
                                    </FormGroup>
                                    <Field
                                        label="Количество"
                                        type="number"
                                        name="count"
                                        min={1}
                                        max={2000000000}
                                        placeholder="1-2000000000"
                                        description="Количество предметов"
                                        component={FormikRowInput} />
                                </>
                            )
                        }
                        <div className="text-center mt-3">
                            <Button type="submit" color="primary" disabled={isSubmitting || !isValid}>
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
}

export default EditDailyGiftForm;