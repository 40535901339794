import React, { FC, PropsWithChildren, useCallback } from "react";
import { ModalProps } from "@constructor/components/modals";
import { useBalance } from "@hooks/selectors/user.selectors";
import { useDispatch } from "react-redux";
import UserService from "@api/userService";
import { toastr } from "react-redux-toastr";
import { userActions } from "@store/user";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useMarketItemContext } from "./items/context";
import ItemCountHelper from "./itemCountHelper";
import { useLocalizedBlock } from "@services/hooks";
import {ObjectSchema} from "yup";

interface MarketBuyItemFormValues {
    count: number;
}

const MarketBuyItemForm: FC<PropsWithChildren<ModalProps>> = ({ modalToggle, children, ...props }) => {
    const item = useMarketItemContext();
    const balance = useBalance();
    const dispatch = useDispatch();

    const globalYup = useLocalizedBlock('yup.message.errors');
    const formMessages = useLocalizedBlock('forms.market.buy');

    const maxCount = Math.min(item ? Math.floor(balance / item.discountPrice) : 0, 10000);

    const onSubmit = useCallback(async (values: MarketBuyItemFormValues) => {
        if (!item) {
            return;
        }

        const api = new UserService();
        const result = await api.buyMarketItem({
            items: [
                {
                    id: item.id,
                    count: values.count,
                    price: item.discountPrice
                }
            ]
        });

        if (result.ok) {
            toastr.success(formMessages['toast.title'], formMessages['toast.success']);
            dispatch(userActions.loadBalance());
            dispatch(userActions.inventory.loadInventoryItems(true));
            if (modalToggle) {
                modalToggle();
            }
        } else {
            if (result.errorCode === 'PriceMismatch') {
                toastr.error(formMessages['toast.title'], formMessages['errors.price_mismatch']);
                dispatch(userActions.loadMarket());
            } else if (result.errorCode === 'ItemNotFound') {
                toastr.error(formMessages['toast.title'], formMessages['errors.item_not_found']);
                dispatch(userActions.loadMarket());
            } else if (result.errorCode === 'NotEnoughMoney') {
                toastr.error(formMessages['toast.title'], formMessages['errors.not_enough_money']);
                dispatch(userActions.loadBalance());
            } else if (result.errorCode === 'ConcurrencyError') {
                toastr.error(formMessages['toast.title'], formMessages['errors.concurrency']);
            } else if (result.errorCode === 'LimitExceeded') {
                const limit = Array.isArray(result.errorData) ? result.errorData?.find(x => x.shopItemId === item.id) : null;
                toastr.error(formMessages['toast.title'], formMessages['errors.limit_exceeded'].replace('{limit}', limit?.limit).replace('{current}', limit?.limitValue));
            } else if (result.errorCode === 'CountError') {
                toastr.error(formMessages['toast.title'], formMessages['errors.count_error']);
            } else {
                toastr.error(formMessages['toast.title'], formMessages['errors.unknown']);
            }
        }
    }, [dispatch, item, modalToggle, formMessages]);

    if (!item) {
        return null;
    }

    const initialValues: MarketBuyItemFormValues = {
        count: 1
    };

    const validate: ObjectSchema<MarketBuyItemFormValues> = Yup.object({
        count: Yup.number()
            .min(1, globalYup['notless']?.replace('{min}', '1'))
            .max(maxCount, globalYup['notmore']?.replace('{max}', String(maxCount)))
            .required(globalYup['required'])
    });

    return (
        <Formik initialValues={initialValues} validationSchema={validate} onSubmit={onSubmit}>
            <Form {...props}>
                <ItemCountHelper>
                    {children}
                </ItemCountHelper>
            </Form>
        </Formik>
    );
}

export default MarketBuyItemForm;