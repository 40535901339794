import {FC, PropsWithChildren, useMemo} from "react";
import {useAvailableLanguages} from "@hooks/languageHooks";
import {injectChildArray} from "@constructor/functions";
import {useLocalizationContext} from "./context";

interface LanguagesListChildProps {
    language_name: string;
    language_code: string;
    select_language: () => void;
}

const LanguagesList: FC<PropsWithChildren> = ({children}) => {
    
    const context = useLocalizationContext();
    const availableLanguages = useAvailableLanguages();

    const setCurrentLanguage = context?.setCurrentLanguage;
    
    const data = useMemo<LanguagesListChildProps[]>(() => availableLanguages.map(x => ({
        language_name: x.name,
        language_code: x.code,
        select_language: () => setCurrentLanguage && setCurrentLanguage(x.code)
    })), [availableLanguages, setCurrentLanguage]);
    
    return injectChildArray(data, children, 'language_code');
}

export default LanguagesList;