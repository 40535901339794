import { ItemInput } from "@components/consumer/helpers";
import { useRole } from "@hooks/userHooks";
import { GoalRewardType } from "@interfaces/consumer/refs";
import { FormikHelpers, useFormik } from "formik";
import { FC, useMemo } from "react";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as Yup from 'yup';
import {ObjectSchema} from "yup";

interface GoalRewardFormProps {
    initialValues: GoalRewardFormValues;
    onSubmit: (values: GoalRewardFormValues, actions: FormikHelpers<GoalRewardFormValues>) => Promise<any>;
    editForm?: boolean;
}

export interface GoalRewardFormValues {
    name: string;
    order: number;
    isActive: boolean;
    type: GoalRewardType;

    balance?: number;

    itemId?: string;
    count?: number;
}

const GoalRewardForm: FC<GoalRewardFormProps> = ({ initialValues, onSubmit, editForm }) => {

    const validationSchema: ObjectSchema<GoalRewardFormValues> = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .max(256)
            .required(),
        order: Yup.number()
            .min(-65000)
            .max(65000)
            .required(),
        isActive: Yup.boolean()
            .required(),
        type: Yup.mixed<GoalRewardType>()
            .oneOf([GoalRewardType.Balance, GoalRewardType.Item])
            .required(),

        balance: Yup.number()
            .when("type", {
                is: GoalRewardType.Balance,
                then: schema => schema
                    .min(1)
                    .max(1000000)
                    .required()
            }),

        itemId: Yup.string()
            .when('type', {
                is: GoalRewardType.Item,
                then: schema => schema
                    .required()
            }),
        count: Yup.number()
            .when('type', {
                is: GoalRewardType.Item,
                then: schema => schema
                    .min(1)
                    .max(2000000000)
                    .required()
            })
    }), []);

    const {
        values,
        isSubmitting,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        errors
    } = useFormik({
        onSubmit,
        initialValues,
        validationSchema
    });

    const disabled = !useRole('consumer.goal.edit')

    return (
        <Form onSubmit={handleSubmit} onBlur={handleBlur}>
            <FormGroup row>
                <Label sm="2">Название</Label>
                <Col sm="10">
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        invalid={Boolean(touched.name && errors.name)}
                        disabled={disabled} />
                    <FormFeedback valid={false}>{errors.name}</FormFeedback>
                </Col>
            </FormGroup>
            {
                editForm ? (
                    <FormGroup row>
                        <Label sm={2}>Активно</Label>
                        <Col sm={10}>
                            <FormGroup check>
                            <Input
                                type="switch"
                                id="isActive"
                                name="isActive"
                                checked={values.isActive}
                                onChange={e => setFieldValue('isActive', e.target.checked)}
                                disabled={disabled} />
                            </FormGroup>
                        </Col>
                    </FormGroup>
                ) : (
                    <FormGroup row>
                        <Label sm={2}>Тип</Label>
                        <Col sm={10} role='group'>
                            <FormGroup check>
                                <Input
                                    type='radio'
                                    name='type'
                                    id='goal-rew-balance'
                                    value={GoalRewardType.Balance}
                                    checked={values.type === GoalRewardType.Balance}
                                    onChange={() => setFieldValue('type', GoalRewardType.Balance)}
                                    disabled={disabled} />
                                    <Label check>Баланс</Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input
                                    type='radio'
                                    name='type'
                                    id='goal-rew-item'
                                    value={GoalRewardType.Item}
                                    checked={values.type === GoalRewardType.Item}
                                    onChange={() => setFieldValue('type', GoalRewardType.Item)}
                                    disabled={disabled} />
                                    <Label check>Предмет</Label>
                            </FormGroup>
                        </Col>
                    </FormGroup>
                )
            }
            {
                values.type === GoalRewardType.Balance && (
                    <FormGroup row>
                        <Label sm="2">Сумма</Label>
                        <Col sm="10">
                            <Input
                                id="balance"
                                name="balance"
                                type="number"
                                value={values.balance}
                                onChange={handleChange}
                                invalid={Boolean(touched.balance && errors.balance)}
                                disabled={disabled}
                                placeholder='Сумма награды' />
                            <FormFeedback valid={false}>{errors.balance}</FormFeedback>
                        </Col>
                    </FormGroup>
                )
            }
            {
                values.type === GoalRewardType.Item && (
                    <>
                        <FormGroup row>
                            <Label sm={2}>Предмет</Label>
                            <Col sm={10} role='group'>
                                <ItemInput name='itemId' value={values.itemId} setFieldValue={setFieldValue} />
                                <FormFeedback valid={false}>{errors.itemId}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm="2"></Label>
                            <Col sm="10">
                                <Input
                                    label="Количество"
                                    type="number"
                                    name="count"
                                    min={1}
                                    max={2000000000}
                                    placeholder="1-2000000000"
                                    value={values.count}
                                    onChange={handleChange}
                                    invalid={Boolean(touched.count && errors.count)}
                                    disabled={disabled}
                                    description="Количество предметов" />
                                <FormFeedback valid={false}>{errors.count}</FormFeedback>
                            </Col>
                        </FormGroup>
                    </>
                )
            }
            <div className="text-center mt-3">
                <Button type="submit" color="primary" disabled={isSubmitting || !isValid || disabled}>
                    Сохранить
                </Button>
            </div>
        </Form>
    )
}

export default GoalRewardForm;