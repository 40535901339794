import { FC } from "react";
import { useQueryContext } from "./context";

interface QueryTableHeadProps {
    columns?: Record<string, any>;
    headProps?: Record<string, any>;
}

const QueryTableHead: FC<QueryTableHeadProps> = ({ columns = {}, headProps }) => {

    const query = useQueryContext();

    if (!query?.columns) {
        return null;
    }

    if(typeof columns !== 'object') {
        console.error('Column props in QueryTableHead must be an object');
        columns = {};
    }

    return (
        <thead {...headProps}>
            <tr>
            {
                query.columns.map(column => {
                    if (column.hidden) {
                        return null;
                    }
                    return (
                        <th key={column.name} {...columns[column.name]}>{column.display}</th>
                    )
                })
            }
            </tr>
        </thead>
    )
}

export default QueryTableHead;