import {PanelStore, ReduxStore} from '@interfaces/store';
import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit';
import {
    BreadcrumbInfo,
    PanelLanguage,
    PanelLanguages,
    PanelSettings,
    PanelTableState,
    ReactTableState
} from '@interfaces/panel';
import {Theme} from '@interfaces/panel/themes';
import PanelService from '../services/api/panelService';
import {DownloadFileInfo} from '@interfaces/panel/files';
import FilesService from '@api/filesService';
import FetchResult from '@models/fetchResult';
import {KeyValueSet} from '@interfaces/helpers';
import {EPaymentCurrency, IPaymentSettings} from "@interfaces/user/payments";

interface WithId {
    id: string;
}

interface Error {
    error: any;
}

const slice = createSlice({
    initialState: {
        settings: {
            loaded: false,
            loading: false,
            error: null,
            data: {
                donateMax: 0,
                donateMin: 0,
                projectName: "",
                baseUrl: "/",
                recaptchaPublic: "",
                title: '',
                isActive: false,
                defaultLang: 'ru',
                aggregators: [],
                paymentCurrency: '₽'
            }
        },
        breadcrumbs: {},
        languages: {},
        tableStates: {},
        tableStates2: {},
        modalStates: {},
        theme: {
            loaded: false,
            loading: false,
            error: null,
            data: undefined
        },
        files: {},
        vars: {},
        paymentSettings: {
            defaultCurrency: EPaymentCurrency.RUB,
            currencies: [],
            donateMin: 0,
            donateMax: 0
        }
    } as PanelStore,
    name: 'panelStore',
    reducers: {
        setSettingsLoading: (state) => {
            state.settings = {
                ...state.settings,
                loaded: false,
                loading: true,
                error: null
            }
        },
        setSettingsError: (state, action) => {
            state.settings = {
                ...state.settings,
                loaded: false,
                loading: false,
                error: action.payload
            }
        },
        setSettings: (state, action: PayloadAction<PanelSettings>) => {
            state.settings = {
                loaded: true,
                loading: false,
                error: null,
                data: {
                    ...state.settings.data,
                    ...action.payload
                }
            }
        },
        setPaymentSettings: (state, action:PayloadAction<IPaymentSettings>) =>{
            state.paymentSettings = action.payload;
        },
        setBreadcrumb: (state, action: PayloadAction<BreadcrumbInfo>) => {
            const bc = action.payload;
            state.breadcrumbs[bc.code] = bc;
        },
        deleteBreadcrumb: (state, action: PayloadAction<string>) => {
            delete state.breadcrumbs[action.payload];
        },
        setLanguage: (state, action: PayloadAction<PanelLanguage>) => {
            const lang = action.payload;
            state.languages[lang.code] = lang;
        },
        setLanguages: (state, action: PayloadAction<PanelLanguage[]>) => {
            const languages: Record<string, PanelLanguage> = {};
            action.payload.forEach(lang => {
                languages[lang.code] = lang;
            });

            state.languages = languages;
        },
        setSelectedLanguage: (state, action: PayloadAction<string>) => {
            state.settings.data.selectedLanguage = action.payload;
        },
        setTableState: (state, action: PayloadAction<PanelTableState & WithId>) => {
            const { id, ...tState } = action.payload;
            state.tableStates[id] = tState;
        },
        setTableState2: (state, action: PayloadAction<{id: string, state: Partial<ReactTableState>}>) => {
            state.tableStates2[action.payload.id] = {
                ...state.tableStates2[action.payload.id],
                ...action.payload.state
            }
        },
        toggleModalState: (state, action: PayloadAction<string | number>) => {
            const id = action.payload;
            state.modalStates[id] = !state.modalStates[id];
        },
        setModalState: (state, { payload: { id, state: modalState } }: PayloadAction<{ id: string, state: boolean }>) => {
            state.modalStates[id] = modalState;
        },
        clearModalState: (state, { payload: id }: PayloadAction<string>) => {
            delete state.modalStates[id];
        },
        setThemeLoading: (state) => {
            state.theme = {
                ...state.theme,
                loading: true,
                error: null
            }
        },
        setThemeError: (state, action) => {
            state.theme = {
                ...state.theme,
                loading: false,
                error: action.payload
            }
        },
        setTheme: (state, action: PayloadAction<Theme>) => {
            state.theme = {
                loaded: true,
                loading: false,
                error: null,
                data: action.payload
            }
        },
        setFiles: (state, { payload: files }: PayloadAction<DownloadFileInfo[]>) => {
            files.forEach(file => {
                state.files[file.id] = {
                    loaded: true,
                    loading: false,
                    error: null,
                    data: file
                };
            });
        },
        setFilesLoading: (state, { payload: ids }: PayloadAction<string[]>) => {
            ids.forEach(id => {
                state.files[id] = {
                    ...state.files[id],
                    loading: true,
                    loaded: false,
                    error: null
                };
            });
        },
        setFilesError: (state, { payload: ids }: PayloadAction<string[]>) => {
            ids.forEach(id => {
                state.files[id] = {
                    ...state.files[id],
                    loading: false,
                    loaded: false,
                    error: true
                };
            });
        },
        setVariable: (state, { payload: { name, value } }: PayloadAction<{ name: string, value: string | number }>) => {
            state.vars[name] = value;
        },
        setVariables: (state, { payload: vars }: PayloadAction<KeyValueSet<string | number>>) => {
            state.vars = {
                ...state.vars,
                ...vars
            }
        },
        deleteVariable: (state, { payload: name }: PayloadAction<string>) => {
            delete state.vars[name];
        },
        clearVariables: (state) => {
            state.vars = {}
        }
    }
});

const {
    deleteBreadcrumb,
    setBreadcrumb,
    setLanguage,
    setLanguages,
    setSelectedLanguage,
    setSettings,
    setSettingsError,
    setSettingsLoading,
    setTableState,
    setTableState2,
    setTheme,
    setThemeError,
    setThemeLoading,
    toggleModalState,
    setModalState,
    clearModalState,
    setFiles,
    setFilesError,
    setFilesLoading,
    setPaymentSettings,

    setVariable,
    setVariables,
    deleteVariable,
    clearVariables
} = slice.actions;

export const { reducer: panelReducer } = slice;

export const panelActions = {
    setBreadcrumb,
    deleteBreadcrumb,
    setLanguage,
    setLanguages,
    setSelectedLanguage,
    setTableState,
    setTableState2,
    toggleModalState,
    setModalState,
    clearModalState,
    setPaymentSettings,
    loadPanelSettings: () => async (dispatch: Dispatch) => {
        dispatch(setSettingsLoading());
        try {
            const api = new PanelService();
            const info = await api.fetchPanelInfo();
            dispatch(setSettings({
                ...info.ProjectInfo,
                paymentCurrency: "₽"
            }));
            return info;
        }
        catch (e) {
            dispatch(setSettingsError(e));
        }
    },
    loadTheme: (name: string) => async (dispatch: Dispatch) => {
        dispatch(setThemeLoading());
        try {
            const api = new PanelService();
            const theme = await api.fetchPanelTheme(name);
            dispatch(setTheme(theme));
            return theme;
        }
        catch (e) {
            dispatch(setThemeError(e));
        }
    },
    loadLanguage: (name: string) => async (dispatch: Dispatch) => {
        try {
            const api = new PanelService();
            const lang = await api.fetchPanelLanguage(name);
            dispatch(setLanguage(lang));
            return lang;
        }
        catch (e: any) {
        }
    },
    loadFiles: (fileIds: string[], silent: boolean = false) => async (dispatch: Dispatch) => {

        if (!silent) {
            dispatch(setFilesLoading(fileIds));
        }
        try {
            const api = new FilesService();
            const files = await api.getFiles(fileIds);

            if (files.success) {
                const errorIds = fileIds.filter(id => files.data.findIndex(f => f.id === id) === -1);
                dispatch(setFiles(files.data));
                dispatch(setFilesError(errorIds));
            }
            else {
                dispatch(setFilesError(fileIds));
            }

            return files;
        }
        catch (e: any) {
            return e as FetchResult<DownloadFileInfo[]>;
        }
    },
    setVariable: (data: { name: string, value: string | number }) => (dispatch: Dispatch, getState: () => ReduxStore) => {
        dispatch(setVariable(data));
        const { panel: { vars } } = getState();
        saveVars(vars);
    },
    loadVariables: () => (dispatch: Dispatch) => {
        const store = localStorage.getItem(`globalVars`);
        if (store) {
            const vars = JSON.parse(store) as KeyValueSet<string | number>;
            if (vars) {
                dispatch(setVariables(vars));
            }
        }
    },
    clearVariables: () => (dispatch: Dispatch) => {
        localStorage.removeItem('globalVars');
        dispatch(clearVariables());
    },
    deleteVariable: (name: string) => (dispatch: Dispatch, getState: () => ReduxStore) => {
        dispatch(deleteVariable(name));
        const { panel: { vars } } = getState();
        saveVars(vars);
    }
}

function saveVars(vars: KeyValueSet<string | number>) {
    if (Object.keys(vars).length === 0) {
        localStorage.removeItem('globalVars');
    }
    else {
        localStorage.setItem('globalVars', JSON.stringify(vars));
    }
}