import { usePromisedDispatch } from "@hooks/helperHooks";
import { useLoginServerAuto } from "@hooks/selectors/game.selectors";
import {FC, PropsWithChildren, useCallback} from "react";
import { LoginIdProps } from "@constructor/components/loginServer";
import { PutGameAccountModel } from "@models/user/data";
import { toastr } from "react-redux-toastr";
import { userActions } from "@store/user";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from 'yup';
import {useLocalizedBlock} from "@services/hooks";

interface CreateAccountFormValues {
    loginId: string;
    account: string;
    password: string;
    repeatPassword: string;
}

interface CreateAccountFormProps extends LoginIdProps {
    modalToggle?: () => void;
}

const CreateAccountForm: FC<PropsWithChildren<CreateAccountFormProps>> = ({ children, login_id, modalToggle, ...props }) => {

    const loginServer = useLoginServerAuto(login_id);
    const dispatch = usePromisedDispatch();

    const loginId = loginServer?.id;

    const globalYup = useLocalizedBlock('yup.message.errors');
    const formMessages = useLocalizedBlock('forms.accounts.create');

    const validation = Yup.object().shape({
        loginId: Yup.string()
            .required(formMessages['yup.message.errors.select_server']),
        account: Yup.string()
            .min(4, globalYup['notless'].replace('{min}', '4'))
            .max(32, globalYup['notmore'].replace('{max}', '32'))
            .required(globalYup['required']),
        password: Yup.string()
            .min(6, globalYup['notless'].replace('{min}', '6'))
            .max(32, globalYup['notmore'].replace('{max}', '32'))
            .required(globalYup['required'])
            .matches(/[A-Z]/, globalYup['matches.uppercase'])
            .matches(/[a-z]/, globalYup['matches.lowercase'])
            .matches(/[0-9]/, globalYup['matches.digits']),
        repeatPassword: Yup.string()
            .required(globalYup['required'])
            .oneOf([Yup.ref('password')], globalYup['matches.password'])
    });

    const onSubmit = useCallback(async (values: CreateAccountFormValues, actions: FormikHelpers<CreateAccountFormValues>) => {

        const account: PutGameAccountModel = {
            loginServer: values.loginId,
            name: values.account,
            password: values.password
        };

        const result = await dispatch(userActions.createAccount(account));

        if (result.ok) {
            toastr.success(formMessages['toast.title'], formMessages['toast.success']);
            if (modalToggle) {
                modalToggle();
            }
        }
        else {
            if (result.errorCode === 'AlreadyExist') {
                actions.setFieldError('account', formMessages['errors.exists']);
            }
            else if (result.errorCode === 'InvalidName') {
                actions.setFieldError('account', formMessages['errors.invalid_name']);
            }
            else if (result.errorCode === 'QueryError') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.query']);
            }
            else {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.unknown']);
            }
        }
    }, [dispatch, formMessages, modalToggle]);

    return (
        <Formik
            validationSchema={validation}
            onSubmit={onSubmit}
            initialValues={{
                account: '',
                password: '',
                repeatPassword: '',
                loginId: loginId || ''
            } as CreateAccountFormValues}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    )
}

export default CreateAccountForm;