import {Button, Card, Dropdown, DropdownButton, Table} from 'react-bootstrap';
import {ConsumerUserAccount, ConsumerUserFull} from '@interfaces/consumer/users';
import { useLocalizedBlock, useConsumerProjectInfo } from '@services/hooks';
import {AccountActions, GameAccount, UserDetails} from './details';
import { FC, Fragment } from 'react';
import {FalconCardHeader} from "@components/falcon/common";
import AdvanceTableProvider from "@components/falcon/common/tables/context";
import {AdvanceTable} from "@components/falcon/common/tables";
import {useTable} from "@hooks/tableHooks";
import {ColumnDef} from "@tanstack/react-table";
import {fromUtcDate} from "@services/helpers";
import {useLoginServers} from "@hooks/selectors/consumer/project.selectors";

interface ConsumerUserDetailsProps {
    user: ConsumerUserFull;
}

const ConsumerUserDetails: FC<ConsumerUserDetailsProps> = ({ user }) => {

    const lang = useLocalizedBlock('consumer.user.main');
    const loginServers = useLoginServers();

    return (
        <>
            {
                loginServers.map((login) => {
                    const accounts = user.accounts.filter(acc => acc.loginId === login.id);
                    return (
                        <Card className="h-100" key={login.id}>
                            <FalconCardHeader
                                title={login.name}
                                titleTag="h6"
                                className="py-2"
                                light
                            />
                            <Card.Body className="position-relative pb-0 pt-0">
                                <AccountsList accounts={accounts} />
                            </Card.Body>
                        </Card>
                    )
            })}
        </>
    )
}

interface AccountsListProps {
    accounts: ConsumerUserAccount[];
}

const columns: ColumnDef<ConsumerUserAccount>[] = [
    {
        accessorKey: 'name',
        header: 'Логин',
        cell: ({row}) => row.original.name
    },
    {
        accessorKey: 'createTime',
        header: 'Дата создания',
        cell: ({row: {original}}) => fromUtcDate(original.createTime).format('lll')
    },
    {
        accessorKey: 'actions',
        header: '',
        enableSorting: false,
        meta: {
            headerProps: {
                scope: 'col',
            },
            cellProps: {
                className: "text-end"
            }
        },
        cell: ({row}) => (
            <AccountActions account={row.original}/>
        )
    }
]

const AccountsList: FC<AccountsListProps> = ({accounts}) => {

    const table = useTable({
        columns
    }, accounts);

    return (
        <AdvanceTableProvider table={table}>
            <div className="mx-ncard">
                <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle font-sans-serif"
                    rowClassName="align-middle white-space-nowrap fw-semibold"
                    tableProps={{
                        className: 'fs-10 mb-0'
                    }}
                />
            </div>
        </AdvanceTableProvider>
    )
}

const GameAccountPlaceholder: FC = () => (
    <tr className='placeholder-wave'>
        <td><span className='placeholder col-6 bg-light' /></td>
        <td><span className='placeholder col-9 bg-light' /></td>
        <td><span className='placeholder col-5 bg-light' /></td>
    </tr>
)

export default ConsumerUserDetails;