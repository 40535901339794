import { useSelector } from 'react-redux';
import { ReduxStore } from '@interfaces/store';
import { KeyValueSet } from '@interfaces/helpers';
import { useMemo } from "react";

export function useDefaultLanguageCode() {
    return useSelector(({panel}: ReduxStore) => panel.settings.data.defaultLang);
}

export function useCurrentLanguageCode() {
    const defaultLanguage = useSelector(({panel}: ReduxStore) => panel.settings.data.defaultLang);
    const selectedLanguage = useSelector(({panel}: ReduxStore) => panel.settings.data.selectedLanguage);
    const userLanguage = useSelector(({user: {user}}: ReduxStore) => user.loaded ? user.data.lang : undefined);

    return userLanguage || selectedLanguage || defaultLanguage;
}

export function useCurrentLanguage() {
    const currentLanguage = useCurrentLanguageCode();
    const defaultLanguage = useDefaultLanguageCode();
    return useSelector(({panel}: ReduxStore) => panel.languages[currentLanguage] ?? panel.languages[defaultLanguage]);
}

export function useAvailableLanguages() {
    const languages = useSelector(({ panel }: ReduxStore) => panel.languages);
    return Object.values(languages);
}

export function useAvailableLanguagesCodes() {
    const languages = useSelector(({ panel }: ReduxStore) => panel.languages);
    return Object.keys(languages);
}

/**
 * Локализация строки
 * @param text 
 * @param replaces 
 */
export function useLocalizedText<T extends string | number | undefined>(text: T, replaces: Record<string, string | number> = {}): T {
    const lang = useCurrentLanguage();

    if (text === undefined || text === null || typeof text === 'number') {
        return text;
    }

    let template = (lang && lang?.strings[text.toString()]) || text.toString();

    if (typeof replaces === 'object') {
        Object.entries(replaces).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                template = template.replace(`{${key}}`, value.toString());
            }
        });
    }

    return template as T;
}

/**
 * Локализация строк из массива
 * @param texts 
 * @param replaces 
 */
export function useLocalizedTexts(texts: string[], replaces: any = {}){

    const lang = useCurrentLanguage();

    return useMemo(() => {
        const result: KeyValueSet<string> = {};

        texts.forEach(text => {
            let template = lang?.strings[text] || text;
            Object.entries<string>(replaces).forEach((val) => {
                template = template.replace(`{${val[0]}}`, val[1]);
            });
            result[text] = template;
        });

        return result;
    }, [texts, lang?.strings, replaces]);
}

/**
 * Локализация всех строк, начинающихся с block
 * @param block 
 * @param replaces 
 */
export function useLocalizedBlock(block: string, replaces?: Record<string, string | number>) {

    const lang = useCurrentLanguage();
    return useMemo(() => {

        if(!lang){
            return {};
        }

        const result: KeyValueSet<string> = {};

        const entries = Object.entries(lang.strings).filter(e => e[0].startsWith(block));
        entries.forEach(e => {
            let template = e[1];

            if(replaces) {
                Object.entries(replaces).forEach((val) => {
                    template = template.replace(`{${val[0]}}`, val[1].toString());
                });
            }

            result[e[0].replace(`${block}.`, '')] = template;
        });

        return result;
    }, [lang, block, replaces]);
}