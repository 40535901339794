import UserService from "@api/userService";
import { useUserInfo } from "@hooks/userHooks";
import { userActions } from "@store/user";
import { Form, Formik, FormikHelpers } from "formik";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import * as Yup from 'yup';
import { useLocalizedBlock } from "@services/hooks";

interface ChangeProfileFormValues {
    userName: string;
    publicName: string;
    email: string;
}

const ChangeProfileForm: FC<PropsWithChildren> = ({ children, ...props }) => {

    const dispatch = useDispatch();
    const user = useUserInfo();
    const globalYup = useLocalizedBlock('yup.message.errors');
    const formMessages = useLocalizedBlock('forms.settings.profile');

    const onSubmit = useCallback(async ({ publicName }: ChangeProfileFormValues, actions: FormikHelpers<ChangeProfileFormValues>) => {

        const api = new UserService();
        const result = await api.patchUserData({ publicName });

        if (result.ok) {
            toastr.success(formMessages['toast.title'], formMessages['toast.success']);
            dispatch(userActions.data.loadUserData(true));
        }
    }, [dispatch, formMessages]);

    const validation = useMemo(() => Yup.object().shape({
        publicName: Yup.string()
            .min(5, globalYup['notless']?.replace('{min}', '5'))
            .max(30, globalYup['notmore']?.replace('{max}', '30'))
            .required(globalYup['required'])
    }), [globalYup]);

    const initial: ChangeProfileFormValues = {
        userName: user.userName,
        email: user.email,
        publicName: user.publicName
    };

    return (
        <Formik
            initialValues={initial}
            validationSchema={validation}
            onSubmit={onSubmit}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    );
}

export default ChangeProfileForm;