import {FC, Fragment} from "react";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {useQueriesContext} from "@components/consumer/queries/context";
import {useConsumerProjectInfo} from "@services/hooks";

interface QueriesDropdownProps {
    icon?: IconProp;
    queryParam?: string;
}

const QueriesDropdown2: FC<QueriesDropdownProps> = ({icon = "ellipsis-h", queryParam}) => {

    const {
        queries,
        current: {
            executing
        },
        executeQuery
    } = useQueriesContext();

    const serverIds = queries.map(query => query.serverId)
        .filter((value, index, self) => self.indexOf(value) === index);

    const {data: {loginServers, gameServers}} = useConsumerProjectInfo();

    if(!queries || queries.length === 0) {
        return null;
    }

    return (
        <Dropdown
            className="font-sans-serif btn-reveal-trigger ms-1"
            align="end"
        >
            <Dropdown.Toggle
                variant="reveal"
                size="sm"
                data-boundary="viewport"
                className="text-600 btn-reveal"
            >
                <FontAwesomeIcon icon={icon} className="fs-11" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="border py-0">
                <div className="py-2">
                    {
                        serverIds.map((serverId, idx) => {

                            const serverName = loginServers.find(x => x.id === serverId)?.name
                                || gameServers.find(x => x.id === serverId)?.name;

                            const serverQueries = queries.filter(x => x.serverId === serverId);

                            return (
                                <Fragment key={serverId}>
                                    <Dropdown.Header>{serverName}</Dropdown.Header>
                                    {serverQueries.map(query => (
                                        <Dropdown.Item
                                            key={query.id}
                                            disabled={executing}
                                            onClick={() => executeQuery(query, queryParam)}
                                        >
                                            {query.displayName}
                                        </Dropdown.Item>
                                    ))}
                                    {idx < serverIds.length - 1 && <Dropdown.Divider />}
                                </Fragment>
                            )
                        })
                    }
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default QueriesDropdown2;