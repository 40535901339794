import { injectChild } from "@components/constructor";
import { useFormikContext } from "formik";
import {FC, PropsWithChildren, useMemo} from "react";

interface SubmitChildProps {
    disabled: boolean;
    form_submitting: boolean;
    form_valid: boolean;
}

const Submit: FC<PropsWithChildren> = ({ children }) => {

    const context = useFormikContext();

    const data = useMemo<SubmitChildProps>(() => ({
        disabled: context.isSubmitting || !context.isValid,
        form_submitting: context.isSubmitting,
        form_valid: context.isValid
    }), [context.isSubmitting, context.isValid])

    return injectChild(data, children);
}

export default Submit;