import UserService from "@api/userService";
import { usePromisedDispatch } from "@hooks/helperHooks";
import { useBalance } from "@hooks/selectors/user.selectors";
import { ICodeTypeBalance } from "@interfaces/user/bonusCodes";
import { userActions } from "@store/user";
import { Form, Formik } from "formik";
import {FC, PropsWithChildren, useCallback, useMemo} from "react"
import { toastr } from "react-redux-toastr";
import * as Yup from 'yup';
import {useLocalizedBlock} from "@services/hooks";

interface BonusCodeBuyFormValues {
    sum: number;
}

const BonusCodeBuyForm: FC<PropsWithChildren> = ({ children, ...props }) => {
    const dispatch = usePromisedDispatch();
    const balance = useBalance();
    
    const globalYup = useLocalizedBlock('yup.message.errors');
    const formMessages = useLocalizedBlock('forms.bonus.buy');

    const validate = useMemo(() => Yup.object().shape({
        sum: Yup.number()
            .min(100, globalYup['min_amount'].replace('{min}', '100'))
            .max(balance, globalYup['max_amount'].replace('{max}', balance.toString()))
            .required(globalYup['required'])
    }), [balance, globalYup]);

    const onSubmit = useCallback(async (values: BonusCodeBuyFormValues) => {
        const api = new UserService();
        const result = await api.buyBonusCode({
            sum: values.sum
        });

        if(result.success) {
            toastr.success(
                formMessages['toast.title'],
                formMessages['toast.success']
                    .replace('{code}', result.data.code)
                    .replace('{sum}', (result.data.valueInfo as ICodeTypeBalance).value.toString())
            );

            dispatch(userActions.loadBalance());
            dispatch(userActions.loadPersonalBonusCodes());
        }
        else {
            if(result.errorCode === 'SmallSum') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.small_sum']);
            }
            else if(result.errorCode === 'NotEnoughtMoney') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.not_enough']);
            }
            else if(result.errorCode === 'BalanceError') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.balance']);
            }
        }
    }, [dispatch, formMessages]);

    const initial: BonusCodeBuyFormValues = {
        sum: Math.min(100, balance)
    };

    return (
        <Formik
            initialValues={initial}
            validationSchema={validate}
            onSubmit={onSubmit}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    )
}

export default BonusCodeBuyForm;