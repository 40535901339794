import { useRole } from "@hooks/userHooks";
import { GoalConditionType } from "@interfaces/consumer/refs";
import { useFormik } from "formik";
import { FC, useMemo } from "react";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as Yup from 'yup';
import {ObjectSchema} from "yup";

export interface GoalConditionFormProps {
    initialValues: GoalConditionFormValues;
    onSubmit: (values: GoalConditionFormValues) => Promise<any>;
    editForm?: boolean;
}

export interface GoalConditionFormValues {
    name: string;
    isActive: boolean;
    type: GoalConditionType;
    donateValue?: number;
}

const GoalConditionForm: FC<GoalConditionFormProps> = ({ initialValues, onSubmit, editForm }) => {

    const validationSchema: ObjectSchema<GoalConditionFormValues> = useMemo(() => Yup.object({
        name: Yup.string()
            .max(256)
            .required(),
        isActive: Yup.boolean()
            .required(),
        type: Yup.mixed<GoalConditionType>()
            .oneOf(Object.values(GoalConditionType))
            .required(),
        donateValue: Yup.number()
            .when("type", {
                is: GoalConditionType.Donate,
                then: schema => schema
                    .min(1)
                    .max(1000000)
                    .required()
            })
    }), []);

    const {
        values,
        isSubmitting,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        errors
    } = useFormik({
        onSubmit,
        initialValues,
        validationSchema
    });

    const disabled = !useRole('consumer.goal.edit')

    return (
        <Form onSubmit={handleSubmit} onBlur={handleBlur}>
            <FormGroup row>
                <Label sm="2">Название</Label>
                <Col sm="10">
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        invalid={Boolean(touched.name && errors.name)}
                        disabled={disabled} />
                    <FormFeedback valid={false}>{errors.name}</FormFeedback>
                </Col>
            </FormGroup>
            {
                editForm ? (
                    <FormGroup row>
                        <Label sm={2}>Активно</Label>
                        <Col sm={10}>
                            <Input
                                type="switch"
                                id="isActive"
                                name="isActive"
                                checked={values.isActive}
                                onChange={e => setFieldValue('isActive', e.target.checked)}
                                disabled={disabled} />
                        </Col>
                    </FormGroup>
                ) : (
                    <FormGroup row>
                        <Label sm={2}>Тип</Label>
                        <Col sm={10} role='group'>
                            <FormGroup check>
                                <Input
                                    type='radio'
                                    name='type'
                                    id='goal-condition-man'
                                    value={GoalConditionType.Manual}
                                    checked={values.type === GoalConditionType.Manual}
                                    onChange={() => setFieldValue('type', GoalConditionType.Manual)}
                                    disabled={disabled} />
                                <Label check>Через API</Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input
                                    type='radio'
                                    name='type'
                                    id='goal-condition-don'
                                    value={GoalConditionType.Donate}
                                    checked={values.type === GoalConditionType.Donate}
                                    onChange={() => setFieldValue('type', GoalConditionType.Donate)}
                                    disabled={disabled} />
                                <Label check>Сумма доната</Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input
                                    type='radio'
                                    name='type'
                                    id='goal-condition-sup'
                                    value={GoalConditionType.SignUp}
                                    checked={values.type === GoalConditionType.SignUp}
                                    onChange={() => setFieldValue('type', GoalConditionType.SignUp)}
                                    disabled={disabled} />
                                <Label check>Регистрация</Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input
                                    type='radio'
                                    name='type'
                                    id='goal-condition-supr'
                                    value={GoalConditionType.SignUpRef}
                                    checked={values.type === GoalConditionType.SignUpRef}
                                    onChange={() => setFieldValue('type', GoalConditionType.SignUpRef)}
                                    disabled={disabled} />
                                <Label check>Регистрация по приглашению</Label>
                            </FormGroup>
                        </Col>
                    </FormGroup>
                )
            }
            {
                values.type === GoalConditionType.Donate && (
                    <FormGroup row>
                        <Label sm="2">Сумма</Label>
                        <Col sm="10">
                            <Input
                                id="donateValue"
                                name="donateValue"
                                type="number"
                                value={values.donateValue}
                                onChange={handleChange}
                                invalid={Boolean(touched.donateValue && errors.donateValue)}
                                disabled={disabled} />
                            <FormFeedback valid={false}>{errors.donateValue}</FormFeedback>
                        </Col>
                    </FormGroup>
                )
            }
            <div className="text-center mt-3">
                <Button type="submit" color="primary" disabled={isSubmitting || !isValid || disabled}>
                    Сохранить
                </Button>
            </div>
        </Form>
    )
}

export default GoalConditionForm;