import { ConstructionComponentInfo } from '@interfaces/constructor/constructor';
import Text from './components/Text';
import RSI from './infos/reactstrap';
import CP from './infos/controlPanel';
import Forms from './infos/forms';
import {Link, NavLink, Redirect, Route, Switch} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme } from '@interfaces/panel/themes';
import Portal from './components/panel/portal';
import { ConstructionComponent } from './constructionComponent';
import Slider from "react-slick";
import {Fragment} from "react";

export function getComponent(name: string, theme?: Theme): ConstructionComponentInfo | string {

    if (name.startsWith('RS.')) {
        name = name.substring(3) + 'Info';
        return RSI[name];
    }

    if (name.startsWith('CP.')) {
        name = name.substring(3) + 'Info';
        return CP[name];
    }

    if (name.startsWith('Forms.')) {
        name = name.substring(6) + 'Info';
        return Forms[name];
    }

    if (name.startsWith('CC.') && theme?.components) {
        return {
            component: ConstructionComponent
        };
    }

    switch (name) {
        case 'FontAwesomeIcon':
            return {
                component: FontAwesomeIcon
            };

        case 'Fragment':
            return {
                component: Fragment
            };

        case 'Link':
            return {
                component: Link
            };
        case 'Text':
            return {
                component: Text
            };
        case 'NavLink':
            return {
                component: NavLink
            };
        case 'Portal':
            return {
                component: Portal
            };
        case 'Switch':
            return {
                component: Switch
            };
        case 'Route':
            return {
                component: Route
            };
        case 'Redirect':
            return {
                component: Redirect
            }
        case 'Slider': {
            return {
                component: Slider
            }
        }
        default:
            return name;
    }

}
