import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import {injectChild} from '@components/constructor';
import {useModal, useModalBoundaries} from '@services/hooks/panelHooks';
import {ConstructionComponentInfo} from '@interfaces/constructor/constructor';
import {useDispatch} from 'react-redux';
import {panelActions} from '@store/panelStore';

export interface ModalContextType {
    isOpen: boolean;
    toggle?: () => any;
    close?: () => any;
}

const ModalContext = createContext<ModalContextType>(undefined as any);

export const ModalProvider = ModalContext.Provider;
export const ModalConsumer = ModalContext.Consumer;

export function useModalContext() {
    return useContext<ModalContextType>(ModalContext);
}

export interface ModalProps {
    modalOpen?: boolean;
    modalToggle?: () => void;
}

const PanelModal: FC<any> = ({ children, ...props }) => {

    const { isOpen, toggle } = useModalContext();

    return (
        <Modal {...props} isOpen={isOpen} toggle={toggle}>
            {children}
        </Modal>
    )
}

const PanelModalHeader: FC<any> = ({ children, ...props }) => {
    const { toggle } = useModalContext();

    return (
        <ModalHeader {...props} toggle={toggle}>
            {children}
        </ModalHeader>
    )
}

interface PanelModalContextProps {
    modal_id: string,
    fadeInTime?: number;
    fadeOutTime?: number;
    clearFadeIn?: boolean;
    closeOnFadeIn?: boolean;
    openOnFadeOut?: boolean;
    unmount?: boolean;
}

const PanelModalContext: FC<PropsWithChildren<PanelModalContextProps>> = ({ children, modal_id, fadeInTime, fadeOutTime, unmount, clearFadeIn, closeOnFadeIn, openOnFadeOut }) => {

    const dispatch = useDispatch();
    const state = useModal(modal_id, {
        fadeInTime,
        fadeOutTime,
        unmount,
        clearFadeIn,
        closeOnFadeIn,
        openOnFadeOut
    });
    
    const modalRef = useModalBoundaries({
        isOpen: state.isOpen,
        closeModal: state.closeModal
    });

    const data = useMemo(() => ({
        modalToggle: state.toggleModal,
        modalOpen: state.isOpen,
        modal_fade_in: state.fadeIn,
        modal_fade_out: state.fadeOut,
        modal_boundaries_ref: modalRef
    }), [modalRef, state.fadeIn, state.fadeOut, state.isOpen, state.toggleModal]);

    useEffect(() => {
        return () => {
            dispatch(panelActions.clearModalState(modal_id));
        }
    }, [dispatch, modal_id]);

    if(state.unmounted) {
        return null;
    }

    return (
        <ModalProvider value={state}>
            {injectChild(data, children)}
        </ModalProvider>
    )
}

const PanelSimpleModalContext: FC<PropsWithChildren> = ({ children }) => {

    const [state, setState] = useState<ModalContextType>({
        isOpen: false,
        toggle: () => { }
    });

    //Инициализируем стейт
    useEffect(() => {

        //создаем функцию переключатель
        const tgl = () => setState((state) => ({
            isOpen: !state.isOpen,
            toggle: state.toggle
        }));

        //и запихиваем в стейт
        setState({
            isOpen: false,
            toggle: tgl
        })
    }, []);

    const closeModal = useCallback(() => {
        setState(state => (
            {
                ...state,
                isOpen: false
            }
        ));
    }, []);
    
    const modalRef = useModalBoundaries({
        isOpen: state.isOpen,
        closeModal: closeModal
    });
    
    const data = useMemo(() => ({
        modalToggle: state.toggle,
        modalOpen: state.isOpen,
        modal_boundaries_ref: modalRef
    }), [modalRef, state.isOpen, state.toggle]);

    return (
        <ModalProvider value={state}>
            {injectChild(data, children)}
        </ModalProvider>
    )
}

interface ModalTogglerProps {
    modal_id: string,
    tag: string | React.ElementType,
    onClick?: () => any;
}

const ModalToggler: FC<PropsWithChildren<ModalTogglerProps>> = ({ modal_id, children, onClick, tag: Tag, ...props }) => {

    const state = useModal(modal_id);

    const action = useCallback(() => {
        state.toggleModal();
        onClick && onClick();
        //console.log("tik")
    }, [onClick, state]);

    return (
        <Tag onClick={action} {...props}>
            {children}
        </Tag>
    )
}

const ModalContextInfo: ConstructionComponentInfo = {
    component: PanelModalContext,
    addedChildProps: [
        'modalOpen',
        'modalToggle'
    ]
}

const SimpleModalContextInfo: ConstructionComponentInfo = {
    component: PanelSimpleModalContext,
    addedChildProps: [
        'modalOpen',
        'modalToggle'
    ]
}

const ModalInfo: ConstructionComponentInfo = {
    component: PanelModal,
    requiredParent: [
        'ModalContext'
    ]
}

const ModalHeaderInfo: ConstructionComponentInfo = {
    component: PanelModalHeader,
    requiredParent: [
        'ModalContext'
    ]
}

const ModalTogglerInfo: ConstructionComponentInfo = {
    component: ModalToggler
}

export {
    ModalInfo,
    ModalHeaderInfo,
    ModalContextInfo,
    SimpleModalContextInfo,
    ModalTogglerInfo
}