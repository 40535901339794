import { injectChild } from "@components/constructor";
import { useFormikContext } from "formik";
import {FC, PropsWithChildren, ReactText, RefObject, useCallback, useMemo, useState} from "react";
import { DropdownContextState, DropdownProvider } from "./context";
import {useModalBoundaries} from "@hooks/panelHooks";

interface DropdownProps {
    name: string;
}

interface DropdownChildProps {
    dropdown_opened: boolean;
    dropdown_value: ReactText;
    dropdown_open: () => void;
    dropdown_close: () => void;
    dropdown_toggle: () => void;
    dropdown_boundaries_ref: RefObject<HTMLElement>
}

const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({ name, children }) => {

    const { setFieldValue, setFieldTouched, values } = useFormikContext<any>();
    const [isOpened, setOpened] = useState(false);
    const value = values[name];

    const setValue = useCallback((value: ReactText) => {
        setFieldTouched(name, true, false);
        setFieldValue(name, value);        
        setOpened(false);
    }, [name, setFieldTouched, setFieldValue]);

    const open = useCallback(() => setOpened(true), []);
    const close = useCallback(() => setOpened(false), []);
    const toggle = useCallback(() => setOpened(!isOpened), [isOpened]);
    
    const ref = useModalBoundaries({
        isOpen: isOpened,
        closeModal: close
    });

    const data = useMemo<DropdownChildProps>(() => ({
        dropdown_opened: isOpened,
        dropdown_open: open,
        dropdown_value: value,
        dropdown_close: close,
        dropdown_toggle: toggle,
        dropdown_boundaries_ref: ref
    }), [close, isOpened, open, ref, toggle, value]);

    const context = useMemo<DropdownContextState>(() => ({
        setValue,
        value: value
    }), [setValue, value])

    return (
        <DropdownProvider value={context}>
            {injectChild(data, children)}
        </DropdownProvider>
    );
}

export default Dropdown;