import UserService from "@api/userService";
import { Form, Formik, FormikHelpers } from "formik";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import { toastr } from "react-redux-toastr";
import * as Yup from 'yup';
import { useLocalizedBlock } from "@services/hooks";

interface ChangePasswordFormValues {
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
}

const ChangePasswordForm: FC<PropsWithChildren> = ({ children, ...props }) => {

    const globalYup = useLocalizedBlock('yup.message.errors');
    const formMessages = useLocalizedBlock('forms.settings.password');

    const validation = useMemo(() => Yup.object().shape({
        oldPassword: Yup.string()
            .min(6, globalYup['notless']?.replace('{min}', '6'))
            .max(64, globalYup['notmore']?.replace('{max}', '64'))
            .required(globalYup['required']),
        newPassword: Yup.string()
            .min(6, globalYup['notless']?.replace('{min}', '6'))
            .max(64, globalYup['notmore']?.replace('{max}', '64'))
            .required(globalYup['required'])
            .matches(/[A-Z]/, globalYup['matches.uppercase'])
            .matches(/[a-z]/, globalYup['matches.lowercase'])
            .matches(/[0-9]/, globalYup['matches.digits']),
        repeatPassword: Yup.string()
            .min(6, globalYup['notless']?.replace('{min}', '6'))
            .max(64, globalYup['notmore']?.replace('{max}', '64'))
            .oneOf([Yup.ref("newPassword")], globalYup['matches.password'])
            .required(globalYup['required']),
    }), [globalYup]);

    const onSubmit = useCallback(async ({ oldPassword, newPassword }: ChangePasswordFormValues, actions: FormikHelpers<ChangePasswordFormValues>) => {

        const api = new UserService();
        const result = await api.changeUserPassword({
            oldPassword,
            newPassword
        });

        if (result.ok) {
            toastr.success(formMessages['toast.title'], formMessages['toast.success']);
            actions.resetForm();
        }
        else {
            if(result.errorCode === 'InvalidPassword') {
                actions.setFieldError('oldPassword', formMessages['errors.invalid_password']);
            }
            else {
                toastr.error(formMessages['toast.title'], formMessages['toast.error']);
            }
        }
    }, [formMessages]);

    const initial: ChangePasswordFormValues = {
        oldPassword: "",
        newPassword: "",
        repeatPassword: ""
    };

    return (
        <Formik
            initialValues={initial}
            onSubmit={onSubmit}
            validationSchema={validation}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    );
}

export default ChangePasswordForm;