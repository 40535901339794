import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import AccountsList from "./accountsList";
import CharactersList from "./charactesList";
import GameServersList from "./gameServersList";
import LoginServersList from "./loginServersList";

const AccountsListInfo: ConstructionComponentInfo = {
    component: AccountsList,
    props: [
        'clear_on_change'
    ],
    addedChildProps: [
        'account_id',
        'account_name'
    ]
}

const CharactersListInfo: ConstructionComponentInfo = {
    component: CharactersList,
    props: [
        'clear_on_change'
    ],
    addedChildProps: [
        'character_id',
        'character_name'
    ]
}

const GameServersListInfo: ConstructionComponentInfo = {
    component: GameServersList,
    props: [
        'clear_on_change'
    ],
    addedChildProps: [
        'game_server_id',
        'game_server_name'
    ],
    injectChild: [
        'available_game_servers'
    ]
}

const LoginServersListInfo: ConstructionComponentInfo = {
    component: LoginServersList,
    props: [
        'clear_on_change'
    ],
    addedChildProps: [
        'login_server_id',
        'login_server_name'
    ],
    injectChild: [
        'available_login_servers'
    ]
}

export {
    AccountsListInfo,
    CharactersListInfo,
    GameServersListInfo,
    LoginServersListInfo
}