import {FC} from "react";
import {useUserCreateDate} from "@hooks/selectors/user.selectors";
import {Text} from "@constructor/components";
import {fromUtcDate} from "@services/helpers";

interface UserCreateDateProps {
    format?: string;
}

const UserCreateDate: FC<UserCreateDateProps> = ({format = 'lll', ...props}) => {
    const createDate = useUserCreateDate();

    return (
        <Text text={fromUtcDate(createDate).format(format)} {...props} />
    )
}

export default UserCreateDate;