import BaseInfoBlock from "./baseInfoBlock";
import RewardBlock from "./rewardBlock";
import {BonusCodeAccess, BonusCodeType} from "@interfaces/consumer/bonusCodes";
import * as Yup from "yup";
import {ObjectSchema} from "yup";
export {
    BaseInfoBlock,
    RewardBlock
}

export interface BonusCodeFormValues {
    code: string;
    count?: number;
    maxUseCount: number;

    timeLimited: boolean;
    beginTime?: Date;
    endTime?: Date;

    isActive: boolean;
    access: BonusCodeAccess;

    type: BonusCodeType;
    itemId?: string;
    itemCount?: number,
    money?: number;
}

export const editBonusFormValidation: ObjectSchema<BonusCodeFormValues> = Yup.object({
    code: Yup.string()
        .min(3)
        .max(36)
        .required(),
    count: Yup.number(),
    maxUseCount: Yup.number()
        .min(0)
        .max(10000)
        .required(),

    timeLimited: Yup.boolean().required(),
    beginTime: Yup.date(),
    endTime: Yup.date(),

    isActive: Yup.boolean().required(),
    access: Yup.mixed<BonusCodeAccess>()
        .oneOf(Object.values(BonusCodeAccess))
        .required(),

    type: Yup.mixed<BonusCodeType>()
        .oneOf(Object.values(BonusCodeType))
        .required(),
    itemId: Yup.string()
        .when("type", {
            is: BonusCodeType.Item,
            then: schema => schema.required()
        }),
    itemCount: Yup.number()
        .when("type", {
            is: BonusCodeType.Item,
            then: schema => schema
                .min(1)
                .max(2000000000)
                .required()
        }),
    money: Yup.number()
        .when("type", {
            is: BonusCodeType.Balance,
            then: schema => schema
                .min(1)
                .max(100000)
                .required()
        })
})