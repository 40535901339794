import { useRole } from "@hooks/userHooks";
import { RefRewardType } from "@interfaces/consumer/refs";
import { FC, useMemo } from "react";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from 'yup';
import { ItemInput } from "@components/consumer/helpers";
import {ObjectSchema} from "yup";

interface RefRewardFormProps {
    initialValues: RefRewardFormValues;
    onSubmit: (values: RefRewardFormValues, actions: FormikHelpers<RefRewardFormValues>) => Promise<any>;
    editForm?: boolean;
}

export interface RefRewardFormValues {
    name: string;
    order: number;
    isActive: boolean;
    type: RefRewardType;

    balance?: number;

    itemId?: string;
    count?: number;

    percent?: number;
    minSum?: number;
    maxSum?: number;
}

const RefRewardForm: FC<RefRewardFormProps> = ({ initialValues, onSubmit, editForm }) => {

    const validationSchema: ObjectSchema<RefRewardFormValues> = useMemo(() => Yup.object({
        name: Yup.string()
            .max(256)
            .required(),
        order: Yup.number()
            .min(-65000)
            .max(65000)
            .required(),
        isActive: Yup.boolean()
            .required(),
        type: Yup.mixed<RefRewardType>()
            .oneOf(Object.values(RefRewardType))
            .required(),

        balance: Yup.number()
            .when("type", {
                is: RefRewardType.Balance,
                then: schema => schema
                    .min(1)
                    .max(1000000)
                    .required()
            }),

        itemId: Yup.string()
            .when('type', {
                is: RefRewardType.Item,
                then: schema => schema
                    .required()
            }),
        count: Yup.number()
            .when('type', {
                is: RefRewardType.Item,
                then: schema => schema
                    .min(1)
                    .max(2000000000)
                    .required()
            }),

        percent: Yup.number()
            .when('type', {
                is: RefRewardType.Percent,
                then: schema => schema
                    .min(0)
                    .max(100)
                    .required()
            }),
        minSum: Yup.number()
            .when('type', {
                is: RefRewardType.Percent,
                then: schema => schema
                    .min(0)
                    .max(1000000)
                    .required()
            }),
        maxSum: Yup.number()
            .when('type', {
                is: RefRewardType.Percent,
                then: schema => schema
                    .min(0)
                    .max(1000000)
                    .required()
            })
    }), []);

    const {
        values,
        isSubmitting,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        errors
    } = useFormik({
        onSubmit,
        initialValues,
        validationSchema
    });

    const disabled = !useRole('consumer.goal.edit')

    return (
        <Form onSubmit={handleSubmit} onBlur={handleBlur}>
            <FormGroup row>
                <Label sm="2">Название</Label>
                <Col sm="10">
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        invalid={Boolean(touched.name && errors.name)}
                        disabled={disabled} />
                    <FormFeedback valid={false}>{errors.name}</FormFeedback>
                </Col>
            </FormGroup>
            {
                editForm ? (
                    <FormGroup row>
                        <Label sm={2}>Активно</Label>
                        <Col sm={10}>
                            <Input
                                type="switch"
                                id="isActive"
                                name="isActive"
                                checked={values.isActive}
                                onChange={e => setFieldValue('isActive', e.target.checked)}
                                disabled={disabled} />
                        </Col>
                    </FormGroup>
                ) : (
                    <FormGroup row>
                        <Label sm={2}>Тип</Label>
                        <Col sm={10} role='group'>
                            <FormGroup check>
                                <Input
                                    type='radio'
                                    name='type'
                                    id='ref-rew-balance'
                                    value={RefRewardType.Balance}
                                    checked={values.type === RefRewardType.Balance}
                                    onChange={() => setFieldValue('type', RefRewardType.Balance)}
                                    disabled={disabled} />
                                <Label check>Баланс</Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input
                                    type='radio'
                                    name='type'
                                    id='ref-rew-item'
                                    value={RefRewardType.Item}
                                    checked={values.type === RefRewardType.Item}
                                    onChange={() => setFieldValue('type', RefRewardType.Item)}
                                    disabled={disabled} />
                                <Label check>Предмет</Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input
                                    type='radio'
                                    name='type'
                                    id='ref-rew-per'
                                    value={RefRewardType.Percent}
                                    checked={values.type === RefRewardType.Percent}
                                    onChange={() => setFieldValue('type', RefRewardType.Percent)}
                                    disabled={disabled} />
                                <Label check>Процент доната</Label>
                            </FormGroup>
                        </Col>
                    </FormGroup>
                )
            }
            {
                values.type === RefRewardType.Balance && (
                    <FormGroup row>
                        <Label sm="2">Сумма</Label>
                        <Col sm="10">
                            <Input
                                id="balance"
                                name="balance"
                                type="number"
                                value={values.balance}
                                onChange={handleChange}
                                invalid={Boolean(touched.balance && errors.balance)}
                                disabled={disabled}
                                placeholder='Сумма награды' />
                            <FormFeedback valid={false}>{errors.balance}</FormFeedback>
                        </Col>
                    </FormGroup>
                )
            }
            {
                values.type === RefRewardType.Item && (
                    <>
                        <FormGroup row>
                            <Label sm={2}>Предмет</Label>
                            <Col sm={10} role='group'>
                                <ItemInput name='itemId' value={values.itemId} setFieldValue={setFieldValue} />
                                <FormFeedback valid={false}>{errors.itemId}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm="2"></Label>
                            <Col sm="10">
                                <Input
                                    label="Количество"
                                    type="number"
                                    name="count"
                                    min={1}
                                    max={2000000000}
                                    placeholder="1-2000000000"
                                    value={values.count}
                                    onChange={handleChange}
                                    invalid={Boolean(touched.count && errors.count)}
                                    disabled={disabled}
                                    description="Количество предметов" />
                                <FormFeedback valid={false}>{errors.count}</FormFeedback>
                            </Col>
                        </FormGroup>
                    </>
                )
            }
            {
                values.type === RefRewardType.Percent && (
                    <>
                        <FormGroup row>
                            <Label sm={2}>Процент</Label>
                            <Col sm="10">
                                <Input
                                    id="percent"
                                    name="percent"
                                    type="number"
                                    value={values.percent}
                                    onChange={handleChange}
                                    invalid={Boolean(touched.percent && errors.percent)}
                                    disabled={disabled}
                                    placeholder='Процент от доната' />
                                <FormFeedback valid={false}>{errors.percent}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Минимальная сумма</Label>
                            <Col sm="10">
                                <Input
                                    id="minSum"
                                    name="minSum"
                                    type="number"
                                    value={values.minSum}
                                    onChange={handleChange}
                                    invalid={Boolean(touched.minSum && errors.minSum)}
                                    disabled={disabled}
                                    placeholder='Минимальная сумма пополнения' />
                                <FormFeedback valid={false}>{errors.minSum}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Максимальная сумма</Label>
                            <Col sm="10">
                                <Input
                                    id="maxSum"
                                    name="maxSum"
                                    type="number"
                                    value={values.maxSum}
                                    onChange={handleChange}
                                    invalid={Boolean(touched.maxSum && errors.maxSum)}
                                    disabled={disabled}
                                    placeholder='Максимальная сумма пополнения' />
                                <FormFeedback valid={false}>{errors.maxSum}</FormFeedback>
                            </Col>
                        </FormGroup>
                    </>
                )
            }
            <div className="text-center mt-3">
                <Button type="submit" color="primary" disabled={isSubmitting || !isValid || disabled}>
                    {editForm ? 'Сохранить' : 'Создать'}
                </Button>
            </div>
        </Form>
    )
}

export default RefRewardForm;