import { useSelector, shallowEqual } from "react-redux";
import { ReduxStore } from "@interfaces/store";
import { useState, useEffect, useMemo, useCallback } from "react";
import { UserAuthLog } from "@interfaces/user/users";
import UserService from '../../api/userService';
import { useFile } from "@hooks/filesHooks";

export function useUserShortNews() {
    return useSelector(({ user }: ReduxStore) => user.shortNews);
}

export function useEmail() {
    return useSelector(({ user }: ReduxStore) => user.user.data.email);
}

export function useBalance() {
    return useSelector(({ user }: ReduxStore) => user.user.data.balance);
}

export function useUserName() {
    return useSelector(({ user }: ReduxStore) => user.user.data.userName);
}

export function usePublicName() {
    return useSelector(({ user }: ReduxStore) => user.user.data.publicName);
}

export function useUserCreateDate() {
    return useSelector(({ user }: ReduxStore) => user.user.data.createTime);
}

export function useAvatarData() {
    return useSelector(({ user: { user: { data } } }: ReduxStore) => ({
        avatar: data.avatar,
        avatarId: data.avatarId
    }), shallowEqual);
}

export function useAvatar() {
    const data = useAvatarData();

    const file = useFile(data.avatarId);
    return file.data?.url || data.avatar;
}

export function useLastAuth() {

    const [loading, setLoading] = useState(true);
    const [log, setLog] = useState<UserAuthLog>();

    useEffect(() => {

        const load = async () => {
            const api = new UserService();
            const result = await api.fetchUserLastAuthLog();
            setLoading(false);

            if (result.ok) {
                setLog(result.result);
            }
        }

        if (loading) {
            load();
        }
    }, [loading]);

    const reload = useCallback(() => setLoading(true), []);

    const result = useMemo(() => {
        return {
            loading,
            log,
            reload
        }
    }, [loading, log, reload])

    return result
}

export function useAccounts(login?: string) {
    return useSelector(({ user: { accounts: { data } } }: ReduxStore) => login ? data.filter(a => a.loginServer === login) : data, shallowEqual);
}

export function useAccount(accountId: string, loginId?: string) {
    return useSelector(({ user: { accounts: { data } } }: ReduxStore) => data.find(a => a.id === accountId && (!loginId || a.loginServer === loginId)));
}

export function useNotifications() {
    return useSelector((state: ReduxStore) => state.user.notifications.data, shallowEqual);
}

export function useInventory() {
    return useSelector(({ user }: ReduxStore) => user.inventory.data);
}

export function useCharacters(account: string, gameServer?: string) {
    return useSelector(({ user: { characters: { data } } }: ReduxStore) => {
        return data.filter(a => a.accountId === account && (!gameServer || a.gameId === gameServer))
    }, shallowEqual);
}