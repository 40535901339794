import { injectChild } from "@components/constructor";
import {FC, PropsWithChildren, ReactText, useCallback, useMemo} from "react";
import { useDropdownContext } from "./context";

interface DropdownOptionProps {
    value: ReactText;
}

interface DropdownOptionChildProps {
    dropdown_option_value: ReactText;
    dropdown_option_selected: boolean;
    dropdown_select_option: () => void;
}
const DropdownOption: FC<PropsWithChildren<DropdownOptionProps>> = ({ value, children }) => {

    const {
        setValue,
        value: selectedValue
    } = useDropdownContext() || {};
    
    const selectOption = useCallback(() => setValue && setValue(value), [setValue, value]);

    const data = useMemo<DropdownOptionChildProps>(() => {
        return {
            dropdown_option_value: value,
            dropdown_select_option: selectOption,
            dropdown_option_selected: selectedValue === value
        }
    }, [selectOption, selectedValue, value])

    return injectChild(data, children);
}

export default DropdownOption;