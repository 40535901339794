import {FC, PropsWithChildren, useCallback, useMemo, useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {Form, Formik, FormikHelpers} from 'formik';
import {toastr} from 'react-redux-toastr';
import * as Yup from 'yup';
import {injectChild} from '@components/constructor';
import {useLocalizedBlock} from '@services/hooks';
import {auth} from '@services/auth';
import {useMetrics, useThemeUrl} from '@hooks/panelHooks';
import {useHistory, useLocation} from 'react-router-dom';
import {useCurrentLanguageCode} from "@hooks/languageHooks";

interface SignUpFormValues {
    username: string
    email: string,
    password: string,
    repeatPassword: string,
    rules: boolean;
    promoCode: string;
}

function useRefCode() {

    const { search } = useLocation();
    const history = useHistory();

    return useMemo(() => {
        const params = new URLSearchParams(search);
        const code = params.get("r");
        const storedCode = localStorage.getItem("refCode");

        if (code && storedCode !== code) {
            localStorage.setItem("refCode", code);
        }

        if (code) {
            params.delete('r');
            history.replace({
                search: params.toString()
            });
        }

        return code || storedCode;
    }, [history, search]);
}

interface SignUpFormProps {
    initialValues?: Partial<SignUpFormValues>;
}

const SignUpForm: FC<PropsWithChildren<SignUpFormProps>> = ({ initialValues, children, ...props }) => {

    const history = useHistory();
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptcha, setRecaptcha] = useState<string | null>(null);
    const locale = useLocalizedBlock('guest.signup');
    const globalYup = useLocalizedBlock('yup.message.errors');
    const redirectUrl = useThemeUrl('signup.success');
    const lang = useCurrentLanguageCode();

    const { reachGoal } = useMetrics();

    const refCode = useRefCode();

    const yup = Yup.object().shape({
        username: Yup.string()
            .min(5, globalYup['notless'].replace('{min}', '5'))
            .max(16, globalYup['notmore'].replace('{max}', '16'))
            .required(globalYup['required']),
        email: Yup.string()
            .email(globalYup['email'])
            .required(globalYup['required']),
        password: Yup.string()
            .min(6, globalYup['notless'].replace('{min}', '6'))
            .max(16, globalYup['notmore'].replace('{max}', '16'))
            .required(globalYup['required'])
            .matches(/[A-Z]/, globalYup['matches.uppercase'])
            .matches(/[a-z]/, globalYup['matches.lowercase'])
            .matches(/[0-9]/, globalYup['matches.digits']),
        repeatPassword: Yup.string()
            .required(globalYup['required'])
            .oneOf([Yup.ref('password')], globalYup['matches.password']),
        promoCode: Yup.string()
            .max(36, globalYup['notmore'].replace('{max}', '36')),
        rules: Yup.boolean()
            .oneOf([true], globalYup['required'])
    });

    const onSubmit = useCallback(async (values: SignUpFormValues, actions: FormikHelpers<SignUpFormValues>) => {

        if (!recaptcha) {
            actions.setFieldError("username", locale['errors.required_recaptcha']);
            return;
        }

        const res = await auth().signUp({
            userName: values.username,
            email: values.email,
            password: values.password,
            confirmPassword: values.repeatPassword,
            recaptcha,
            promoCode: values.promoCode || undefined,
            refCode: refCode || undefined,
            language: lang
        });

        reachGoal('signUp');

        if (res.success) {
            const result = res.data.result;

            if (result === 'CheckMail') {
                toastr.success(locale['toast.header'], locale['toast.success.check_mail']);
            }
            else if (result === 'SmtpError') {
                toastr.warning(locale['toast.header'], locale['toast.errors.smtp_error']);
            }
            else {
                toastr.success(locale['toast.header'], locale['toast.success']);
            }

            if (redirectUrl) {
                history.push(redirectUrl);
            }

            localStorage.removeItem('r');
        }
        else {
            let handled = false;
            if (res.errorCode === 'DuplicateUserName') {
                actions.setFieldError("username", locale['errors.duplicate_user_name']);
                handled = true;
            }
            if (res.errorCode === 'DuplicateEmail') {
                actions.setFieldError("email", locale['errors.duplicate_email']);
                handled = true;
            }
            if (res.errorCode === 'InvalidUserName') {
                actions.setFieldError("username", locale['errors.invalid_user_name']);
                handled = true;
            }
            if (res.errorCode === 'InvalidRecaptcha') {
                toastr.error(locale['header'], locale['errors.recaptcha']);
                handled = true;
            }
            if (res.errorCode === 'ForbiddenEmail') {
                actions.setFieldError("email", locale['errors.forbidden_email']);
                handled = true;
            }
            if (res.errorCode === 'CodeNotFound') {
                actions.setFieldError("promoCode", locale['errors.code_not_found']);
                handled = true;
            }
            if (res.errorCode === 'CodeExpired') {
                actions.setFieldError("promoCode", locale['errors.code_expired']);
                handled = true;
            }
            if (res.errorCode === 'CodeUsed') {
                actions.setFieldError("promoCode", locale['errors.code_used']);
                handled = true;
            }
            if (!handled) {
                toastr.error(locale['toast.header'], locale['toast.errors.internal_error']);
            }
            recaptchaRef.current?.reset();
        }
    }, [recaptcha, refCode, lang, reachGoal, locale, redirectUrl, history]);

    const initial: SignUpFormValues = {
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
        rules: false,
        promoCode: '',
        ...initialValues
    }

    return (
        <Formik
            initialValues={initial}
            onSubmit={onSubmit}
            validationSchema={yup}>
            <Form {...props}>
                {
                    injectChild({
                        recaptcha: {
                            ref: recaptchaRef,
                            onChange: setRecaptcha
                        }
                    }, children)
                }
            </Form>
        </Formik>
    )
}

export default SignUpForm;