import { ConstructionComponentInfo } from "@interfaces/constructor/constructor"
import { ErrorMessage } from "formik"
import FormField from "./field"
import FormFieldValue from "./fieldValue";
import FormReCAPTCHA from "./reCaptcha"
import Submit from "./submit"
import CountHelper from "./countHelper"
import FormHelper from "./formHelper";

export interface FormFieldProps {
    className?: string;
    invalidClassName?: string;
}

const FieldInfo: ConstructionComponentInfo = {
    component: FormField
}

const FieldValueInfo: ConstructionComponentInfo = {
    component: FormFieldValue,
    props: [
        'name',
        'tag'
    ],
    requiredProps: [
        'name'
    ]
}

const SubmitInfo: ConstructionComponentInfo = {
    component: Submit,
    addedChildProps: [
        'disabled',
        'form_submitting',
        'form_valid'
    ]
}

const ReCAPTCHAInfo: ConstructionComponentInfo = {
    component: FormReCAPTCHA,
    requiredChildProps: [
        'recaptcha'
    ],
    injectChild: [
        'recaptcha'
    ]
}

const ErrorMessageInfo: ConstructionComponentInfo = {
    component: ErrorMessage
}

export {
    FieldInfo,
    FieldValueInfo,
    SubmitInfo,
    ReCAPTCHAInfo,
    ErrorMessageInfo,

    CountHelper,
    FormHelper
}