import {FC} from "react";
import {useFormikContext} from "formik";
import {Text} from "@constructor/components";

interface FormFieldValueProps {
    name: string;
}

const FormFieldValue: FC<FormFieldValueProps> = ({name, ...props}) => {
    const {
        values
    } = useFormikContext<{ [key: string]: string }>();

    const value = values[name] || '';
    return (
        <Text {...props} text={value}/>
    )
}

export default FormFieldValue;