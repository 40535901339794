import {FC, PropsWithChildren, useCallback, useMemo, useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormikHelpers, Formik, Form } from 'formik';
import { ResetModel } from '@models/auth/reset-model';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { injectChild } from '@components/constructor';
import { useLocalizedBlock } from '@services/hooks';
import { auth } from '@services/auth';
import { useThemeUrl } from '@hooks/panelHooks';

interface ResetPasswordFormValues {
    password: string;
    repeatPassword: string;
}

const ResetPasswordForm: FC<PropsWithChildren> = ({ children, ...props }) => {

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptcha, setRecaptcha] = useState<string | null>(null);
    const history = useHistory();
    const { code } = useParams<{ code: string }>();
    const redirectUrl = useThemeUrl("signin");
    const locale = useLocalizedBlock('guest.password.reset');
    const yupErrors = useLocalizedBlock('yup.message.errors', {
        min: 6,
        max: 64
    });

    const onSubmit = useCallback(async (values: ResetPasswordFormValues, actions: FormikHelpers<ResetPasswordFormValues>) => {

        if (!recaptcha) {
            actions.setFieldError("password", locale['errors.recaptcha']);
            return;
        }

        const data: ResetModel = {
            code,
            password: values.password,
            recaptcha
        }

        const result = await auth().resetPassword(data);

        const options: BasicToastrOptions = {
            timeOut: 10000,
            showCloseButton: true,
            progressBar: true
        }

        if (result.ok) {
            toastr.success(locale['header.toastr'], locale['success'], options);
            history.replace(redirectUrl || '/auth/');
        }
        else {
            if (result.errorCode === 'UserNotFound') {
                toastr.error(locale['header.toastr'], locale['errors.user_not_found'], options);
            }
            else if (result.errorCode === 'InvalidPassword') {
                actions.setFieldError('password', locale['errors.invalid_password']);
            }
            else if (result.errorCode === 'UpdateError') {
                toastr.error(locale['header.toastr'], locale['errors.update_error'], options);
            }
        }
    }, [code, history, locale, recaptcha, redirectUrl]);

    const validation = useMemo(() => Yup.object().shape({
        password: Yup.string()
            .min(6, yupErrors['notless'])
            .max(64, yupErrors['notmore'])
            .required(yupErrors['required'])
            .matches(/[A-Z]/, yupErrors['matches.uppercase'])
            .matches(/[a-z]/, yupErrors['matches.lowercase'])
            .matches(/[0-9]/, yupErrors['matches.digits']),
        repeatPassword: Yup.string()
            .required(yupErrors['required'])
            .oneOf([Yup.ref('password')], yupErrors['matches.password'])
    }), [yupErrors]);

    const initial: ResetPasswordFormValues = {
        password: '',
        repeatPassword: ''
    }

    return (
        <Formik
            initialValues={initial}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            <Form {...props}>
                {
                    injectChild({
                        recaptcha: {
                            ref: recaptchaRef,
                            onChange: setRecaptcha
                        }
                    }, children)
                }
            </Form>
        </Formik>
    )
}

export default ResetPasswordForm;