import { injectChildArray } from "@components/constructor";
import { useGameServers, useLoginServers } from "@hooks/selectors/game.selectors";
import { useAccounts } from "@hooks/selectors/user.selectors";
import { useFormikContext } from "formik";
import {FC, PropsWithChildren, useEffect, useMemo} from "react";
import { SelectorsFormsValues } from "../selectors";

interface LoginServersListProps {
    clear_on_change?: boolean;
    auto_fill_account?: boolean;
    auto_fill_game?: boolean;
    available_login_servers?: string[];
}

interface LoginServersListChildProps {
    login_server_id: string;
    login_server_name: string;
}

const LoginServersList: FC<PropsWithChildren<LoginServersListProps>> = (
    {
        available_login_servers,
        clear_on_change,
        auto_fill_account,
        auto_fill_game,
        children
    }) => {

    const logins = useLoginServers()
        .filter(x => !available_login_servers || available_login_servers.includes(x.id));
    const { values: { selected_login_server }, touched, setFieldValue, setFieldTouched } = useFormikContext<SelectorsFormsValues>();

    const gameServers = useGameServers(selected_login_server);
    const accounts = useAccounts(selected_login_server);

    const defaultGame = (auto_fill_game && selected_login_server && gameServers.length === 1) ? gameServers[0].id : '';
    const defaultAcc = (auto_fill_account && selected_login_server && accounts.length === 1) ? accounts[0].id: '';

    useEffect(() => {
        if (touched.selected_login_server && clear_on_change) {
            setFieldTouched('selected_account', false, false);
            setFieldTouched('selected_game_server', false, false);

            setFieldValue('selected_account', defaultAcc, true);
            setFieldValue('selected_game_server', defaultGame, true);
        }
    }, [clear_on_change, setFieldTouched, setFieldValue, touched.selected_login_server, selected_login_server, defaultAcc, defaultGame]);

    const data = useMemo<LoginServersListChildProps[]>(() => logins.map(login => {
        return {
            login_server_id: login.id,
            login_server_name: login.name
        }
    }), [logins]);

    return injectChildArray(data, children, 'login_server_id');
}

export default LoginServersList;