import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import {useAuth, useCurrentLanguage} from "@services/hooks";
import {injectChild} from "@constructor/functions";
import {usePromisedDispatch} from "@hooks/helperHooks";
import {userActions} from "@store/user";
import {storeSelectedLanguage} from "@services/localization";
import {LocalizationContext, LocalizationProvider} from "@constructor/components/core/localization/context";
import {panelActions} from "@store/panelStore";

interface LocalizationChildProps {
    current_language_code: string;
    current_language_name: string;
}

const Localization: FC<PropsWithChildren> = ({ children }) => {

    const dispatch = usePromisedDispatch();
    
    const currentLanguage = useCurrentLanguage();
    const languageCode = currentLanguage.code || '';
    const languageName = currentLanguage.name || '';
    
    const { isAuthed } = useAuth();

    const data = useMemo<LocalizationChildProps>(() => ({
        current_language_code: languageCode,
        current_language_name: languageName
    }), [languageCode, languageName]);

    const setCurrentLanguage = useCallback((language: string) => {

        if(isAuthed) {
            dispatch(userActions.data.setLanguage(language));
        }
        dispatch(panelActions.setSelectedLanguage(language));        
        storeSelectedLanguage(language);
    }, [dispatch, isAuthed]);

    const contextData = useMemo<LocalizationContext>(() => ({
        currentLanguageCode: languageCode,
        currentLanguageName: languageName,
        setCurrentLanguage
    }), [languageCode, languageName, setCurrentLanguage]);

    return (
        <LocalizationProvider value={contextData}>
            {injectChild(data, children)}
        </LocalizationProvider>
    )
}

export default Localization;