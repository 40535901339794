import { userActions } from "@store/user";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { ModalProps } from "@constructor/components/modals";
import { useInventoryItemContext } from "./context";
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import { SelectorsFormsValues } from "@constructor/components/core/forms/selectors";
import { CountHelper } from "@constructor/components/core/forms";
import { useDefaultSelectorFormValues } from "@hooks/helperHooks";
import InventoryService from "@api/user/inventoryService";
import { useLocalizedBlock } from "@services/hooks";
import {useGameServers, useLoginServers} from "@hooks/selectors/game.selectors";
import {injectChild} from "@constructor/functions";

interface SendInventoryItemFormValues extends SelectorsFormsValues {
    count: number;
}

interface SendInventoryItemFormChildProps {
    available_login_servers?: string[];
    available_game_servers?: string[];
}

const SendInventoryItemForm: FC<PropsWithChildren<ModalProps>> = (
    {
        modalToggle,
        children,
        ...props
    }) => {

    const dispatch = useDispatch();
    const item = useInventoryItemContext();
    const loginServers = useLoginServers();
    const gameServers = useGameServers();

    const availableGameServers = useMemo(() => gameServers.filter(x =>
        (!item?.servers || item.servers.includes(x.id)) && x.canSendItems), [gameServers, item?.servers]);
    const availableLoginServers = useMemo(() => loginServers.filter(x =>
        availableGameServers.find(gs => gs.loginId === x.id)
        || ((!item?.servers || item.servers.includes(x.id)) && x.canSendItems)), [availableGameServers, item?.servers, loginServers]);

    const selectors = useDefaultSelectorFormValues(availableLoginServers.map(x => x.id),
        availableGameServers.map(x => x.id));

    // добавляем локализацию
    const globalYup = useLocalizedBlock('yup.message.errors');
    const formMessages = useLocalizedBlock('forms.inventory.send');

    const onSubmit = useCallback(async (values: SendInventoryItemFormValues) => {
        if (!item) {
            return;
        }

        const api = new InventoryService();
        const result = await api.sendInventoryItem({
            itemId: item.id,
            count: values.count,
            accountId: values.selected_account || '',
            characterId: values.selected_character || '',
            gameServerId: values.selected_game_server
        });

        if (result.ok) {
            toastr.success(formMessages['toast.title'], formMessages['toast.success']);
            dispatch(userActions.inventory.loadInventoryItems(true));
            if (modalToggle) {
                modalToggle();
            }
        }
        else {
            if (result.errorCode === 'NoTarget') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.no_target']);
            }
            else if (result.errorCode === 'ItemNotFound') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.item_not_found']);
                dispatch(userActions.inventory.loadInventoryItems(true));
            }
            else if (result.errorCode === 'NotEnoughtItem') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.not_enought_item']);
                dispatch(userActions.inventory.loadInventoryItems(true));
            }
            else if (result.errorCode === 'AccountNotFound') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.account_not_found']);
            }
            else if (result.errorCode === 'GameNotFound') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.game_not_found']);
            }
            else if (result.errorCode === 'CharNotFound') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.char_not_found']);
                dispatch(userActions.characters.loadCharacters());
            }
            else if (result.errorCode === 'SendError') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.send_error']);
            }
            else if(result.errorCode === 'ServerDisabled') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.server_disabled']);
            }
            else if(result.errorCode === 'SendingDisabled') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.sending_disabled']);
            }
            else if(result.errorCode === 'ServerNotIncluded') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.server_not_included']);
            }
            else {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.unknown']);
            }
        }
    }, [dispatch, item, modalToggle, formMessages]);

    const validate = Yup.object<SendInventoryItemFormValues>({
        count: Yup.number()
            .min(1, globalYup['min_amount'].replace('{min}', '1'))
            .max(item?.count || 0, globalYup['max_amount'].replace('{max}', String(item?.count || 0)))
            .required(globalYup['required']),
        selected_login_server: Yup.string()
            .required(globalYup['required']),
        selected_game_server: Yup.string()
            .when('selected_login_server', {
                is: (ls: string) => {
                    const loginServer = availableLoginServers.find(x => x.id === ls);
                    return !loginServer?.canSendItems;
                },
                then: schema => schema.required(globalYup['required'])
            }),
        selected_account: Yup.string()
            .required(globalYup['required']),
        selected_character: Yup.string()
            .when(['selected_game_server', 'selected_login_server'], {
                is: (gs: string, ls: string) => {
                    const gameServer = availableGameServers.find(x => x.id === gs);
                    const loginServer = availableLoginServers.find(x => x.id === ls);

                    return gameServer?.canSendItems || !loginServer?.canSendItems;
                },
                then: schema => schema.required(globalYup['required'])
            })
    });

    const childData = useMemo<SendInventoryItemFormChildProps>(() => ({
        available_login_servers: availableLoginServers.map(x => x.id),
        available_game_servers: availableGameServers.map(x => x.id)
    }), [availableGameServers, availableLoginServers]);
    
    if (!item) {
        return null;
    }

    const initial: SendInventoryItemFormValues = {
        count: 1,
        ...selectors
    };

    const child = (
        <Formik onSubmit={onSubmit} initialValues={initial} validationSchema={validate}>
            <Form {...props}>
                <CountHelper
                    name='count'
                    min={1}
                    max={item?.count}>
                    {children}
                </CountHelper>
            </Form>
        </Formik>
    );
    
    return injectChild(childData, child);
}

export default SendInventoryItemForm;