import {Formik, Form} from "formik";
import { FC, useMemo } from "react";
import * as Yup from 'yup';
import {Card, Col, Row} from "react-bootstrap";
import {RefFooterBlock, RefMainBlock, RefTypeBlock} from "@components/pages/consumer/refs/forms";

export interface RefFormProps {
    initialValues: RefFormValues;
    onSubmit: (values: RefFormValues) => Promise<any>;
    editForm?: boolean;
}

export interface RefFormValues {
    name: string;
    description: string;
    isActive: boolean;
    hidden: boolean;
    conditionType: ConditionType;
    order: number;
}

export enum ConditionType {
    All = 'all',
    One = 'one'
}

const RefForm: FC<RefFormProps> = ({ initialValues, onSubmit, editForm }) => {

    const validationSchema = useMemo(() => Yup.object<RefFormValues>({
        name: Yup.string()
            .max(256)
            .required(),
        description: Yup.string()
            .max(10000),
        isActive: Yup.boolean()
            .required(),
        hidden: Yup.boolean()
            .required(),
        order: Yup.number()
            .min(-65000)
            .max(65000)
            .required()
    }), []);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            <Form>
                <Row className="g-3">
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row className="flex-between-center">
                                    <Col md>
                                        <h5 className="mb-2 mb-md-0">{editForm ? 'Редактирование' : 'Создание'} бонуса {editForm && `"${initialValues.name}"`}</h5>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={8}>
                        <RefMainBlock editForm={editForm} />
                    </Col>
                    <Col lg={4} className="sticky-sidebar">
                        <RefTypeBlock />
                    </Col>
                    <Col>
                        <RefFooterBlock />
                    </Col>
                </Row>
            </Form>
        </Formik>
    )
}

export default RefForm;