import {PanelLanguage} from "@interfaces/panel";

export function storeLanguages(langs: PanelLanguage[]) {
    localStorage.setItem('localization.languages', JSON.stringify(langs));
}

export function loadLanguages() {
    const json = localStorage.getItem('localization.languages');
    if(json != null) {
        try {
            return JSON.parse(json) as PanelLanguage[];
        }
        catch (error) {
            console.error('Failed to parse stored languages', error);
        }
    }
    return null;
}

export function storeSelectedLanguage(languageCode: string) {
    localStorage.setItem('localization.language', languageCode);
}

export function loadSelectedLanguage() {
    return localStorage.getItem('localization.language');
}