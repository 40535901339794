import { usePromisedDispatch } from "@hooks/helperHooks";
import { PatchGameAccountModel } from "@models/user/data";
import { userActions } from "@store/user";
import { Form, Formik, FormikHelpers } from "formik";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import { BasicToastrOptions, toastr } from "react-redux-toastr";
import { AccountIdProps } from ".";
import { ModalProps } from "../../modals";
import { useAccountContext } from "./context";
import * as Yup from 'yup';
import { useLocalizedBlock } from "@services/hooks";

interface ChangeAccountPasswordFormValues {
    accountId: string;
    newPassword: string;
    repeatPassword: string;
}

const ChangeAccountPasswordForm: FC<PropsWithChildren<AccountIdProps & ModalProps>> = ({ account_id, children, modalToggle, ...props }) => {

    const context = useAccountContext();
    const dispatch = usePromisedDispatch();

    const globalYup = useLocalizedBlock('yup.message.errors');
    const formMessages = useLocalizedBlock('forms.accounts.password');

    const validate = useMemo(() => Yup.object().shape({
        accountId: Yup.string()
            .required(globalYup['required']),
        newPassword: Yup.string()
            .min(6, globalYup['notless']?.replace('{min}', '6'))
            .max(64, globalYup['notmore']?.replace('{max}', '64'))
            .required(globalYup['required']),
        repeatPassword: Yup.string()
            .min(6, globalYup['notless']?.replace('{min}', '6'))
            .max(64, globalYup['notmore']?.replace('{max}', '64'))
            .oneOf([Yup.ref("newPassword")], globalYup['matches.password'])
            .required(globalYup['required']),
    }), [globalYup]);

    const onSubmit = useCallback(async (values: ChangeAccountPasswordFormValues, actions: FormikHelpers<ChangeAccountPasswordFormValues>) => {
        const account: PatchGameAccountModel = {
            id: values.accountId,
            password: values.newPassword
        };

        const result = await dispatch(userActions.patchAccount(account));

        if (result.ok) {
            toastr.success(formMessages['toast.title'], formMessages['toast.success']);
            if (modalToggle) {
                modalToggle();
            }
        }
        else {
            actions.setFieldError('newPassword', formMessages['errors.change']);
        }
    }, [dispatch, formMessages, modalToggle]);

    if (!context && !account_id) {
        console.error('account context and id is null');
        return null;
    }

    return (
        <Formik
            validationSchema={validate}
            onSubmit={onSubmit}
            initialValues={{
                newPassword: '',
                repeatPassword: '',
                accountId: context?.id || account_id || ''
            } as ChangeAccountPasswordFormValues}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    );
}

export default ChangeAccountPasswordForm;