import { injectContextArray } from "@components/constructor";
import { PaginationProps } from "@constructor/components/core/pagination";
import { useTasks } from "@hooks/selectors/user/support.selectors";
import {FC, PropsWithChildren, useMemo} from "react";
import {ISupportTopicContext, SupportTopicProvider} from "../context";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface SupportTopicsListChildProps {
    topic_has_answer: boolean;
    topic_closed: boolean;
    topic_id: string;
}

const SupportTopicsList: FC<PropsWithChildren<PaginationProps>> = ({ pagination_current_page = 0, pagination_per_page = 10, children }) => {

    const {data: tasks} = useTasks();
    const page = tasks.slice(pagination_current_page * pagination_per_page, (pagination_current_page + 1) * pagination_per_page);

    const data = useMemo<InjectContextType<ISupportTopicContext, SupportTopicsListChildProps>[]>(() => page.map((task) => ({
        key: task.id,
        value: {
            topic: task
        },
        childData: {
            topic_has_answer: task.hasNewAnswer,
            topic_closed: task.isClosed,
            topic_id: task.id
        }
    })), [page]);

    return injectContextArray(data, SupportTopicProvider, children);
}

export default SupportTopicsList;