import { useLocalizedText } from '@services/hooks';
import { ElementType, FC } from "react";

interface TextProps {
    text: string | number;
    prefix?: string;
    suffix?: string;
    tag?: string | ElementType;
    replaces?: any; 
}

const Text: FC<TextProps> = ({ text, prefix, suffix, tag: Tag, replaces, ...props }) => {

    const display = useLocalizedText(text, replaces);

    if (Tag) {
        return (
            <Tag {...props}>
                {display}
            </Tag>
        )
    }

    return display;
}

export default Text;