import {FC, PropsWithChildren, useMemo} from "react";
import {useMarketCategoryContext} from "@constructor/components/features/market/categories/context";
import {useMarketItems} from "@hooks/selectors/services.selectors";
import {usePagination} from "@hooks/components/constructor.hooks";
import {PaginationProps} from "@constructor/components/core/pagination";
import {injectChild} from "@constructor/functions";

interface MarketItemsProps {
    market_items_per_page?: number;
}

interface MarketItemsChildProps {
    market_items_count: number;
}

const MarketItems: FC<PropsWithChildren<MarketItemsProps>> = ({market_items_per_page = 10, children}) => {

    const category = useMarketCategoryContext();
    const { items } = useMarketItems(category?.id);

    const pagination = usePagination(market_items_per_page, items.length);
    const data = useMemo<MarketItemsChildProps & PaginationProps>(() => ({
        market_items_count: items.length,
        ...pagination
    }), [items.length, pagination])

    return injectChild(data, children);
}

export default MarketItems;