import {FC, PropsWithChildren, useMemo} from "react";
import {useFile} from "@hooks/filesHooks";
import {injectChild} from "@constructor/functions";

interface MessageAttachmentProps {
    attachment_file_id: string;
}

interface MessageAttachmentChildProps {
    attachment_loading: boolean;
    attachment_url: string;
    attachment_file_name: string;
}

const MessageAttachment: FC<PropsWithChildren<MessageAttachmentProps>> = ({attachment_file_id, children}) => {

    const file = useFile(attachment_file_id);

    const data: MessageAttachmentChildProps = useMemo(() => ({
        attachment_loading: file.loading || true,
        attachment_url: file.data?.url || '',
        attachment_file_name: file.data?.fileName || ''
    }), [file.loading, file.data?.url, file.data?.fileName]);

    return injectChild(data, children);
}

export default MessageAttachment;