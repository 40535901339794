import React, { FC, lazy, Suspense } from "react";
import { Provider } from "react-redux";
import store from "../../redux/store";
import ReduxToastr from "react-redux-toastr";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { PanelLoader } from "../loader";
import Routes from "../../routes/routes";
import { usePanel } from "@hooks/loadingHooks";
import { Chart as ChartJS, registerables } from 'chart.js';

// Lazy load metrics components
const YandexMetrika = lazy(() => import("@components/metrics/yandexMetrika"));
const MailRuCounter = lazy(() => import("@components/metrics/mailRuCounter"));

ChartJS.register(...registerables);
library.add(fas);

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <PanelLoader>
                <Routes />
            </PanelLoader>
            <ReduxToastr
                timeOut={5000}
                newestOnTop={true}
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
            />
            <Metrics />
        </Provider>
    );
};

const Metrics: FC = () => {
    const { ymId, mailRuCounterId } = usePanel();

    return (
        <Suspense fallback={null}>
            {ymId && <YandexMetrika id={ymId}/>}
            {mailRuCounterId && <MailRuCounter id={mailRuCounterId}/>}
        </Suspense>
    )
}

export default App;