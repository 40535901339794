import {FC} from "react";
import {useMarketItemContext} from "./context";
import {Text} from "@constructor/components";

const MarketItemDiscountPrice: FC = (props) => {
    const item = useMarketItemContext();
    if (!item) {
        return null;
    }

    const replaces = {
        price: item.discountPrice
    }

    return (
        <Text text={item.discountPrice} {...props} replaces={replaces} />
    )
}

export default MarketItemDiscountPrice;