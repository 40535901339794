import {FC} from "react";
import { Tooltip as ReactTooltip } from 'react-tippy';
import {useLocalizedText} from "@services/hooks";

interface TooltipProps {
    text: string;
    replaces?: Record<string, string>;
}

const Tooltip: FC<TooltipProps> = ({text, replaces, ...props}) => {

    const localisedText = useLocalizedText(text, replaces);

    if(!localisedText) {
        return null;
    }

    return (
        <ReactTooltip
            title={localisedText}
            {...props}
        />
    )
}

export default Tooltip;