import { FC } from "react";
import { useConfirmEmailContext } from "./context";
import { Text } from '@constructor/components';
import { useLocalizedBlock } from '@services/hooks';

const ConfirmEmailMessage: FC = (props) => {

    const context = useConfirmEmailContext();
    const locale = useLocalizedBlock('guest.email');
    
    if (!context) {
        return null;
    }

    const { error, success, fetching } = context;

    if (error) {
        return <Text text={locale['message.error']} {...props}/>
    }
    if (fetching) {
        return <Text text={locale['message.loading']} {...props}/>
    }
    if (!success) {
        return <Text text={locale['message.not_found']} {...props}/>
    }

    return null;
}

export default ConfirmEmailMessage;