import {FC} from "react";
import {useCurrentLanguage} from "@services/hooks";
import {Text} from "@constructor/components";

const CurrentLanguageName: FC = (props) => {

    const currentLanguage = useCurrentLanguage();
    const languageName = currentLanguage.name || '';
    if(!languageName) {
        return null;
    }

    const replaces = {
        language_name: languageName
    }

    return (
        <Text text={languageName} {...props} replaces={replaces} />
    );
}

export default CurrentLanguageName;