import Market from "./market";
import MarketBuyItemForm from "./marketBuyItemForm";
import {
    MarketItems,
    MarketItemsList,
    MarketItemName,
    MarketItemDiscountPrice,
    MarketItemDescription,
    MarketItemIcon,
    MarketItemPrice,
    MarketItemLimit
} from "./items";
import {MarketCategoriesList, MarketCategory, MarketCategoryName, MarketCategoryIcon, MarketCategoryDescription} from "./categories";
import {ConstructionComponentInfo} from "@interfaces/constructor/constructor";

const MarketInfo: ConstructionComponentInfo = {
    component: Market,
    addedChildProps:[
        'market_loaded',
        'market_loading',
        'market_error',
        'market_items_count',
        'market_categories_count'
    ]
}

const MarketCategoriesListInfo: ConstructionComponentInfo = {
    component: MarketCategoriesList,
    props: [
        'default_category'
    ],
    addedChildProps: [
        'market_category_has_icon',
        'market_category_active',
        'market_category_id',
        'market_category_url',
        'market_category_loading',
        'market_category_loaded',
        'market_category_error',
        'market_category_items_count'
    ]
}

const MarketCategoryInfo: ConstructionComponentInfo = {
    component: MarketCategory,
    props: [
        'category_id',
        'default_category'
    ],
    addedChildProps: [
        'market_category_loading',
        'market_category_loaded',
        'market_category_error',
        'market_category_items_count'
    ]
}

const MarketCategoryNameInfo: ConstructionComponentInfo = {
    component: MarketCategoryName
}

const MarketCategoryDescriptionInfo: ConstructionComponentInfo = {
    component: MarketCategoryDescription
}

const MarketCategoryIconInfo: ConstructionComponentInfo = {
    component: MarketCategoryIcon
}

const MarketItemsInfo: ConstructionComponentInfo = {
    component: MarketItems,
    props: [
        'market_items_per_page'
    ],
    addedChildProps: [
        'market_items_count',
        'pagination_current_page',
        'pagination_total_pages',
        'pagination_per_page',
        'pagination_set_page',
        'pagination_next_page',
        'pagination_prev_page',
    ]
}

const MarketItemsListInfo: ConstructionComponentInfo = {
    component: MarketItemsList,
    addedChildProps: [
        'market_item_has_icon',
        'has_discount',
        'has_limit',
        'item_id',
        'market_item_index'
    ],
    injectChild: [
        'pagination_current_page',
        'pagination_per_page'
    ]
}

const MarketItemNameInfo: ConstructionComponentInfo = {
    component: MarketItemName
}

const MarketItemDescriptionInfo: ConstructionComponentInfo = {
    component: MarketItemDescription
}

const MarketItemPriceInfo: ConstructionComponentInfo = {
    component: MarketItemPrice
}

const MarketItemDiscountPriceInfo: ConstructionComponentInfo = {
    component: MarketItemDiscountPrice
}

const MarketItemLimitInfo: ConstructionComponentInfo = {
    component: MarketItemLimit
}

const MarketItemIconInfo: ConstructionComponentInfo = {
    component: MarketItemIcon,
    addedChildProps: [
        'image_url',
        'image_loading'
    ]
}

const MarketBuyItemFormInfo: ConstructionComponentInfo = {
    component: MarketBuyItemForm,
    injectChild: [
        'modalToggle'
    ]
}

export {
    MarketInfo,

    MarketCategoriesListInfo,
    MarketCategoryInfo,
    MarketCategoryIconInfo,
    MarketCategoryNameInfo,
    MarketCategoryDescriptionInfo,

    MarketItemsInfo,
    MarketItemsListInfo,
    MarketItemNameInfo,
    MarketItemDescriptionInfo,
    MarketItemPriceInfo,
    MarketItemDiscountPriceInfo,
    MarketItemIconInfo,
    MarketItemLimitInfo,
    MarketBuyItemFormInfo
}