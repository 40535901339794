import { injectContextArray } from "@components/constructor";
import { usePromisedDispatch } from "@hooks/helperHooks";
import { useNotifications } from "@hooks/selectors/user.selectors";
import { KeyValueSet } from "@interfaces/helpers";
import { UserNotification } from "@interfaces/user/users";
import { userActions } from "@store/user";
import {FC, PropsWithChildren, useCallback, useMemo, useState} from "react";
import { NotificationProvider } from "./context";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface NotificationsListChildProps {
    notification_unreaded: boolean;
    read_notification: () => void;
    delete_notification: () => void;
}

const NotificationsList: FC<PropsWithChildren> = ({ children }) => {

    const notifications = useNotifications();
    const dispatch = usePromisedDispatch();
    const [fetching, setFetching] = useState<KeyValueSet<boolean>>({});

    const setReaded = useCallback(async (id: string) => {

        const not = notifications.find(n => n.id === id);

        if(not?.isReaded || fetching[id]) {
            return;
        }

        setFetching({
            ...fetching,
            [id]: true
        });

        await dispatch(userActions.readNotification(id));

        setFetching({
            ...fetching,
            [id]: false
        });

    }, [dispatch, fetching, notifications]);

    const del = useCallback(async (id: string) => {
        await dispatch(userActions.deleteNotification(id));
    }, [dispatch]);
    
    const data = useMemo<InjectContextType<UserNotification, NotificationsListChildProps>[]>(() => notifications.map(n => ({
        key: n.id,
        value: n,
        childData: {
            notification_unreaded: !n.isReaded,
            read_notification: () => setReaded(n.id),
            delete_notification: () => del(n.id)
        }
    })), [del, notifications, setReaded]);

    return injectContextArray(data, NotificationProvider, children);
}

export default NotificationsList;