import Localization from "./localization";
import LanguagesList from "./languagesList";
import CurrentLanguageName from "./currentLanguageName";
import {ConstructionComponentInfo} from "@interfaces/constructor/constructor";

const LocalizationInfo: ConstructionComponentInfo = {
    component: Localization,
    addedChildProps: [
        'current_language_code',
        'current_language_name'
    ]
}

const LanguagesListInfo: ConstructionComponentInfo = {
    component: LanguagesList,
    addedChildProps: [
        'language_name',
        'language_code',
        'select_language'
    ]
}

const CurrentLanguageNameInfo: ConstructionComponentInfo = {
    component: CurrentLanguageName
}

export {
    LocalizationInfo,
    LanguagesListInfo,
    CurrentLanguageNameInfo
}