import {FC, useCallback, useState} from 'react';
import { useParams } from 'react-router-dom';
import {FileUploader, fromUtcDate} from '../../../../services';
import { IConsumerMarketItemFull} from '@interfaces/consumer/market';
import { useStateLoader, useBreadcrumbs } from '@hooks/panelHooks';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import { UploadFileInfo } from '@interfaces/panel/files';
import { toastr } from 'react-redux-toastr';
import withPage from '../../../hoc/withPage';
import ConsumerMarketService from '../../../../services/api/consumer/consumerMarketService';
import {Card, Col, Row, Spinner} from "react-bootstrap";
import {FormIconBlock, FormPageFooter, FormPageHeader} from "@components/falcon/common/forms";
import {BaseInfoBlock, ItemBlock, LimitsBlock} from "./forms";
import {EditItemFormValues} from ".";

const EditItemPage: FC = () => {
    const { item_id } = useParams<{ item_id: string }>();
    const loadItem = useCallback(() => {
        const api = new ConsumerMarketService();
        return api.fetchConsumerMarketItem(item_id)
    }, [item_id]);
    const { data: item, loading } = useStateLoader<IConsumerMarketItemFull>(loadItem);

    useBreadcrumbs('consumer.market.item.current.name', true, item?.name);

    if (!item) {
        return (
            <Card>
                <Card.Body className="text-center">
                    {loading ? <Spinner className="mr-2" color="primary" /> : (
                        "Предмет не найден"
                    )}
                </Card.Body>
            </Card>
        )
    }

    return (
        <EditItemForm item={item} />
    )
}

interface EditItemFormProps {
    item: IConsumerMarketItemFull;
}

const EditItemForm: FC<EditItemFormProps> = ({ item }) => {

    const [uploading, setUploading] = useState(false);
    const [uploadState, setUploadState] = useState(0);

    const onProgress = useCallback((event: ProgressEvent<EventTarget>) => {
        setUploadState(event.loaded / event.total * 100);
    }, [setUploadState]);

    const validationSchema = Yup.object<EditItemFormValues>({
        name: Yup.string()
            .max(256)
            .required(),
        description: Yup.string()
            .max(10000),
        price: Yup.number()
            .moreThan(0)
            .required(),
        itemId: Yup.string()
            .required(),
        count: Yup.number()
            .min(1)
            .max(2000000000)
            .required()
    });

    const initialValues: EditItemFormValues = {
        name: item.name,
        description: item.description,
        price: item.price,
        isActive: item.isActive,
        itemId: item.itemId,
        count: item.count,
        icon: null,
        categories: item.categories.map(x => x.id),
        limits: item.limits?.map(x => ({
            id: x.id,
            limit: x.limit,
            startDate: x.startDate ? fromUtcDate(x.startDate).toDate() : undefined,
            endDate: x.endDate ? fromUtcDate(x.endDate).toDate() : undefined
        }))
    }

    const onSubmit = useCallback(async (values: EditItemFormValues) => {
        const api = new ConsumerMarketService();
        const result = await api.patchConsumerMarketItem({
            id: item.id,
            name: values.name,
            description: values.description,
            itemId: values.itemId,
            count: values.count,
            price: values.price,
            isActive: values.isActive,
            uploadIcon: Boolean(values.icon),
            categories: values.categories,
            limits: values.limits.map(x => ({
                id: x.id,
                limit: x.limit,
                startDate: x.startDate?.toUTCString(),
                endDate: x.endDate?.toUTCString()
            }))
        });

        if (result.ok) {
            if (result.result?.upload && values.icon) {
                const upload: UploadFileInfo = result.result?.upload;
                const file = new FileUploader(upload);
                file.onProgress = onProgress;
                try {
                    setUploading(true);
                    await file.upload(values.icon);
                    toastr.success("Редактирование товара", "Предмет успешно обновлен.");
                }
                catch (e) {
                    toastr.warning("Редактирование товара", "Предмет обновлен, но не удалось загрузить изображение.");
                    console.log(e);
                }
                finally {
                    setUploading(false);
                    setUploadState(0);
                }
            }
            else {
                toastr.success("Редактирование товара", "Предмет успешно обновлен.");
            }
        }
        else {
            if(result.errorCode === 'ShopItemNotFound') {
                toastr.error("Редактирование товара", "Товар не найден. Проверьте правильность введенных данных.");
            }
            if (result.errorCode === 'ItemNotFound') {
                toastr.error("Редактирование товара", "Предмет не найден. Обновите страницу и попробуйте еще раз.");
            }
            else if (result.errorCode === 'BucketNotFound') {
                toastr.error("Редактирование товара", "Отсутствует хранилище файлов. Создайте его из панели администратора и повторите попытку.");
            }
            else {
                toastr.error("Редактирование товара", "Внутренняя ошибка");
            }
        }
    }, [item.id, onProgress]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form>
                <Row className="g-3">
                    <Col xs={12}>
                        <FormPageHeader title={`Редактирование товара "${item.name}"`} />
                    </Col>
                    <Col lg={8}>
                        <BaseInfoBlock editForm />
                        <LimitsBlock />
                    </Col>
                    <Col lg={4}>
                        <FormIconBlock
                            iconId={item.iconId}
                            title="Иконка товара"
                            uploading={uploading}
                            uploadProgress={uploadState}
                        />
                        <ItemBlock />
                    </Col>
                    <Col>
                        <FormPageFooter
                            cancelUrl={'/consumer/market/item/'}
                        />
                    </Col>
                </Row>
            </Form>
        </Formik>
    )
}

export default withPage(EditItemPage);