import UserService from "@api/userService";
import { BonusCodeType } from "@interfaces/consumer/bonusCodes";
import { ICodeTypeBalance } from "@interfaces/user/bonusCodes";
import { userActions } from "@store/user";
import { Form, Formik, FormikHelpers } from "formik";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import * as Yup from 'yup';
import {useLocalizedBlock} from "@services/hooks";

interface BonusCodeUseFormValues {
    code: string;
}

const BonusCodeUseForm: FC<PropsWithChildren> = ({ children, ...props }) => {
    const dispatch = useDispatch();
    const formMessages = useLocalizedBlock('forms.bonus.use');
    const globalYup = useLocalizedBlock('yup.message.errors');

    const validate = useMemo(() => Yup.object().shape({
        code: Yup.string()
            .required(globalYup['required'])
    }), [globalYup]);

    const onSubmit = useCallback(async ({ code }: BonusCodeUseFormValues, actions: FormikHelpers<BonusCodeUseFormValues>) => {
        const api = new UserService();
        const result = await api.activateUserBonusCode(code);

        if (result.ok) {
            const code = result.data;
            if(code.type === BonusCodeType.Balance) {
                toastr.success(
                    formMessages['toast.title'],
                    formMessages['toast.success'].replace('{sum}', (code.valueInfo as ICodeTypeBalance).value.toString())
                );
            }
            else {
                toastr.success(formMessages['toast.title'], formMessages['toast.success_simple']);
            }
            
            dispatch(userActions.loadBalance());
            dispatch(userActions.loadUsedBonusCodes());
        }
        else {
            if (result.status === 404 || result.errorCode === 'CodeNotFound') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.not_found']);
            }
            else if (result.errorCode === 'AlreadyUsed') {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.already_used']);
            }
            else {
                toastr.error(formMessages['toast.title'], formMessages['toast.errors.unknown']);
            }
        }
    }, [dispatch, formMessages]);

    const initial: BonusCodeUseFormValues = {
        code: ''
    };

    return (
        <Formik
            initialValues={initial}
            validationSchema={validate}
            onSubmit={onSubmit}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    )
}

export default BonusCodeUseForm;