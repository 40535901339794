import {FC, PropsWithChildren, useEffect, useMemo} from "react";
import { injectChild } from "@components/constructor";
import { useDonateLogs } from "@hooks/selectors/user/payments.selectors";
import { userActions } from "@store/user";
import { useDispatch } from "react-redux";
import { PaginationProps } from "@constructor/components/core/pagination";
import { usePagination } from "@hooks/components/constructor.hooks";

interface DonateLogsProps {
    donate_logs_per_page?: number;
}

interface DonateLogsChildProps {
    donate_logs_loading: boolean;
    donate_logs_count: number;
}

const DonateLogs: FC<PropsWithChildren<DonateLogsProps>> = ({ donate_logs_per_page = 10, children }) => {

    const { loading, data: logs } = useDonateLogs();
    const dispatch = useDispatch();

    const pagination = usePagination(donate_logs_per_page, logs.length);

    useEffect(() => {
        dispatch(userActions.loadDonateLogs());
    }, [dispatch]);

    const data = useMemo<DonateLogsChildProps & PaginationProps>(() => ({
        donate_logs_loading: loading,
        donate_logs_count: loading ? -1 : logs.length,
        ...pagination
    }), [loading, logs.length, pagination]);

    return injectChild(data, children);
}

export default DonateLogs;